import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

import "./styles/Admin.css";

import adminBackgroundPng from "../media/png/background/admin_background.png";

const Admin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const { LogIn } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;   
    const { user } = useAuth();

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user, navigate]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const userData = { email, password };
    
        try {
            const response = await fetch(`${apiUrl}/logIn`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userData),
                credentials: "include"
            });

            const data = await response.json();
    
            if (!response.ok) {
                throw new Error(data.message || "Problème lors de la connexion.");
            }            

            const userId = data.userId;
            LogIn(userId);

            navigate("/");
        } catch (error) {
            console.error("Erreur lors de la connexion:", error);
            alert(error.message);
        }
    };

    return (
        <main className="admin">
            <img src={adminBackgroundPng} alt="Salon & Canapé" className="admin-image" width="1177" height="1046" />
            <div className="admin-connexion">
                <div>
                    <h1>Connectez-vous</h1>
                    <p>Insérez votre mail et votre mot de passe</p>
                
                <form onSubmit={handleSubmit} className="admin-form">
                <div className="admin-form-control">
                    <label htmlFor="email">Identifiant</label>
                    <img src={`${process.env.PUBLIC_URL}/icons/mail.png`} alt="Icone mail" className="admin-icon" width="24" height="24" />
                    <input 
                        id="email"
                        type="text"
                        value={email}
                        onChange={handleEmailChange}
                        required
                    />
                </div>
                <div className="admin-form-control">
                    <label htmlFor="password">Mot de passe</label>
                    <img src={`${process.env.PUBLIC_URL}/icons/lock.png`} alt="Icone cadenas" className="admin-icon" width="24" height="24" />
                    <input 
                        id="password"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                    />
                </div>
                <div className="admin-button-container">
                    <button type="submit" className="admin-button">Se connecter</button>
                </div>
            </form>
            </div>
            </div>
        </main>
    );
};

export default Admin;
import React, { useState, useEffect, useRef, forwardRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { FaEdit } from 'react-icons/fa';
import { useAuth } from "../AuthContext";
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr'; 
import { useLanguage } from "../LanguageContext";

import 'react-datepicker/dist/react-datepicker.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/Gites.css";

import mapBackgroundPng from "../media/png/background/map_background.png";
import videoBackground from "../media/intro.mp4";

registerLocale('fr', fr);

const Gites = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { language } = useLanguage();
    const [selectedGite, setSelectedGite] = useState('');
    const [arrivalDate, setArrivalDate] = useState("");
    const [departureDate, setDepartureDate] = useState("");;
    const [gites, setGites] = useState([]);
    const [giteDescription, setGiteDescription] = useState('');
    const navigate = useNavigate();
    const [editing, setEditing] = useState({ id: null, type: null });
    const [editableText, setEditableText] = useState('');
    const [editingImageGiteId, setEditingImageGiteId] = useState(null);
    const fileInputRef = useRef(null);

    const [unavailableStartDates, setUnavailableStartDates] = useState([]);
    const [unavailableEndDates, setUnavailableEndDates] = useState([]);
    const [reservationsDates, setReservationsDates] = useState([]);
    const [minArrivalDate, setMinArrivalDate] = useState(new Date());
    const [maxDepartureDate, setMaxDepartureDate] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 699);
    const apiUrl = process.env.REACT_APP_API_URL;

    const CustomArrivalInputComponent = forwardRef(({ value, onClick, disabled }, ref) => (
        <button 
            className={`gites-section1-dates-calendar ${disabled ? 'disabled' : ''}`} 
            onClick={onClick} 
            ref={ref} 
            disabled={disabled} 
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }} 
        >
            {value || t("reservation.arrivalDate")}
        </button>
    ));

    const CustomDepartureInputComponent = forwardRef(({ value, onClick, disabled }, ref) => (
        <button 
            className={`gites-section1-dates-calendar ${disabled ? 'disabled' : ''}`} 
            onClick={onClick} 
            ref={ref} 
            disabled={disabled}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        >
            {value || t("reservation.departureDate")}
        </button>
    ));

    const handleImageClick = () => {
        if (!gites) {
            return;
        }

        const gite = gites[0];        
        const [latitude, longitude] = gite.position;

        const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        window.open(url, '_blank');
    };

    useEffect(() => {
        const fetchUnavailableDates = async () => {
            try {
                const response = await fetch(`${apiUrl}/UnavailableDate/${selectedGite}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                const { reservations, seasonPeriods, weekPeriod } = data;
    
                const getDatesInRange = (startDate, endDate, excludeDays = [], excludeStart = false) => {
                    const start = new Date(startDate);
                    const end = new Date(endDate);
                    const dates = [];
    
                    const adjustedStart = excludeStart ? new Date(start.setDate(start.getDate() + 1)) : start;

                    for (let date = new Date(adjustedStart); date <= end; date.setDate(date.getDate() + 1)) {
                        if (!excludeDays.includes(date.getDay())) {
                            dates.push(new Date(date).toISOString().split('T')[0]);
                        }
                    }
    
                    return dates;
                };
    
                let allReservationStartDates = [];
                reservations.forEach(reservation => {
                    const dates = getDatesInRange(reservation.arrival_date, reservation.departure_date);
                    allReservationStartDates = allReservationStartDates.concat(dates);
                });

                let allReservationEndDates = [];
                reservations.forEach(reservation => {
                    const dates = getDatesInRange(reservation.arrival_date, reservation.departure_date, [], true);
                    allReservationEndDates = allReservationEndDates.concat(dates);
                });
                
                let allSeasonDates = [];
                seasonPeriods.forEach(period => {
                    if (period.date_start1 && period.date_end1) {
                        const dates = getDatesInRange(period.date_start1, period.date_end1, [weekPeriod]);
                        allSeasonDates = allSeasonDates.concat(dates);
                    }
                    if (period.date_start2 && period.date_end2) {
                        const dates = getDatesInRange(period.date_start2, period.date_end2, [weekPeriod]);
                        allSeasonDates = allSeasonDates.concat(dates);
                    }
                });
    
                const combinedStartDates = [...new Set([...allReservationStartDates, ...allSeasonDates])]; 
                const combinedEndDates = [...new Set([...allReservationEndDates, ...allSeasonDates])]; 
    
                setUnavailableStartDates(combinedStartDates);
                setUnavailableEndDates(combinedEndDates);
                setReservationsDates(allReservationStartDates);

            } catch (error) {
                console.error("Erreur lors de la récupération des dates indisponibles:", error);
            }
        };
    
        if (selectedGite) {
            fetchUnavailableDates();
        }
    }, [selectedGite, apiUrl]);

    useEffect(() => {
        if (arrivalDate) {
            const firstUnavailableAfterArrival = reservationsDates
                .map(date => new Date(date))
                .sort((a, b) => a - b)
                .find(date => date > arrivalDate);

            if (firstUnavailableAfterArrival) {
                const dayBefore = new Date(firstUnavailableAfterArrival);
                dayBefore.setDate(dayBefore.getDate() - 0);
                setMaxDepartureDate(dayBefore);
            } else {
                setMaxDepartureDate(null);
            }
        }
    }, [arrivalDate, reservationsDates]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 599);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleReservation = () => {
        const params = new URLSearchParams();
        
        if (selectedGite) params.append('gite', selectedGite);

        if (arrivalDate) {
            const formatDate = (date) => {
                const d = new Date(date);
                let month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
        
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
        
                return [year, month, day].join('-');
            };
        
            const formattedArrivalDate = formatDate(arrivalDate);
            params.append('arrival', formattedArrivalDate);
        }
    
        if (departureDate) {
            const formatDate = (date) => {
                const d = new Date(date);
                let month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
        
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
        
                return [year, month, day].join('-');
            };
        
            const formattedDepartureDate = formatDate(departureDate);
            params.append('departure', formattedDepartureDate);
        }
    
        const queryString = params.toString();
        navigate(`/reservation${queryString ? `?${queryString}` : ''}`);
    };

    useEffect(() => {
        fetch(`/data/${language}/gites.json`)
          .then(response => response.json())
          .then(data => {
            setGites(data.gites); 
            setGiteDescription(data.giteDescription);
          })
          .catch(error => console.error('Erreur lors du chargement des gîtes:', error));
      }, [language]);  
      
    const handleEdit = (id, type, text) => {
        setEditing({ id, type });
        setEditableText(text);
    };

    const handleSave = async (id, type) => {    
        const bodyContent = {
            id,
            key: type,
            value: editableText,
            language: language
        };
    
        try {
            const response = await fetch(`${apiUrl}/updateGites`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }

            setGites(gites => gites.map(gite => {
                if (gite.id === id) {
                    return { ...gite, [type]: editableText };
                }
                return gite;
            }));
    
            setEditing({ id: null, type: null });
    
            console.log("Mise à jour réussie");
        } catch (error) {
            console.error("Erreur lors de la mise à jour du gîte :", error);
        }
    };
    
    const handleImageEdit = (giteId) => {
        setEditingImageGiteId(giteId); 
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            console.log("Aucun fichier sélectionné.");
            return;
        }
    
        const formData = new FormData();
    

        formData.append('id', editingImageGiteId);
        formData.append('key', 'image');
        
        formData.append('image', file, file.name);
        formData.append('language', "all");
    
        try {
            const response = await fetch(`${apiUrl}/updateGites`, {
                method: 'POST',
                body: formData,
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
    
            const responseData = await response.json();
            console.log("Réponse du serveur :", responseData.message);
    
        } catch (error) {
            console.error("Erreur lors de l'envoi de l'image :", error);
        }
    
        event.target.value = null;
    }; 

    const handleArrivalDateChange = (date) => {
        setArrivalDate(date);
        setDepartureDate('');
    };    

    return (
        <main className="gites">
            <section className="gites-section1">
                <div className="gites-video-background">
                    <video autoPlay loop muted className="gites-video">
                        <source src={videoBackground} type="video/mp4" alt="Vidéo des gîtes" />
                        Votre navigateur ne supporte pas la balise vidéo.
                    </video>
                </div> 
                <div className="gites-section1-reservation-container">
                    {!isMobile && (
                        <select className="gites-section1-gites-selection"
                            value={selectedGite}
                            onChange={(e) => setSelectedGite(e.target.value)}
                        >
                            <option value="">{t("gites.selection")}</option>
                            {gites.map((gite) => (
                                <option key={gite.id} value={gite.id}>
                                    {gite.name}
                                </option>
                            ))}
                        </select>
                    )}

                    {!isMobile && (
                        <ReactDatePicker
                            selected={arrivalDate}
                            onChange={handleArrivalDateChange}
                            excludeDates={unavailableStartDates}
                            minDate={minArrivalDate}
                            disabled={!selectedGite}
                            locale="fr"
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Date d'arrivée"
                            className={`gites-section1-dates-calendar ${!selectedGite ? 'disabled' : ''}`}
                            customInput={<CustomArrivalInputComponent />}
                        />
                    )}

                    {!isMobile && (
                        <ReactDatePicker
                        selected={departureDate}
                        onChange={date => setDepartureDate(date)}
                        excludeDates={unavailableEndDates}
                        minDate={arrivalDate ? new Date(new Date(arrivalDate).setDate(new Date(arrivalDate).getDate() + 1)) : new Date()}
                        maxDate={maxDepartureDate}
                        disabled={!selectedGite || !arrivalDate}
                        locale="fr"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Date de départ"
                        className={`gites-section1-dates-calendar ${!selectedGite || !arrivalDate ? 'disabled' : ''}`}
                        customInput={<CustomDepartureInputComponent />}
                    />
                    )}

                    <button className="gites-section1-reservation-button" onClick={handleReservation}>{t("gites.book")}</button>
                </div>
            </section> 
            
            <section className="gites-content">
                <section className="gites-section2">
                    <h1>{t("gites.gites")}</h1>
                    {editing.id === 0 && editing.type === 'giteDescription' ? (
                        <>
                            <textarea className="gites-section2-text-edit-area" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="gites-edit-button" onClick={() => handleSave(0, 'giteDescription')}>Enregistrer</button>
                        </>
                    ) : (
                        <>
                            <p>{giteDescription} {user && <FaEdit onClick={() => handleEdit(0, 'giteDescription', giteDescription)} />}</p>
                        </>
                    )}
                    {gites.map((gite, index) => (
                        <div key={gite.id} className={`gites-section2-content ${index % 2 === 0 ? 'reverse' : ''}`}>
                            <img src={gite.image} alt="Extérieur des gîtes" className="gites-section2-image" onClick={() => user && handleImageEdit(gite.id)} />
                            {user && <FaEdit className="edit-icon" onClick={() => handleImageEdit(gite.id)} />}
                            <div className="gites-section2-text">
                                {editing.id === gite.id && editing.type === 'name' ? (
                                    <>
                                        <input className="gites-section2-text-edit" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="gites-edit-button" onClick={() => handleSave(gite.id, 'name')}>Enregistrer</button>
                                    </>
                                ) : (
                                    <>
                                        <h3>{gite.name} {user && <FaEdit onClick={() => handleEdit(gite.id, 'name', gite.name)} />}</h3>
                                    </>
                                )}
                                {editing.id === gite.id && editing.type === 'shortDescription' ? (
                                    <>
                                        <textarea className="gites-section2-text-edit-area" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="gites-edit-button" onClick={() => handleSave(gite.id, 'shortDescription')}>Enregistrer</button>
                                    </>
                                ) : (
                                    <>
                                        <p>{gite.shortDescription} {user && <FaEdit onClick={() => handleEdit(gite.id, 'shortDescription', gite.shortDescription)} />}</p>
                                    </>
                                )}
                                <Link to={`/gites/${gite.id}`} className="gites-section2-button">{t("gites.more")}</Link>
                            </div>
                        </div>
                    ))}
                    <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                </section>

                <section className="gites-section-map">
                    <img src={mapBackgroundPng} alt="Localisation des gîtes" onClick={handleImageClick} style={{cursor: 'pointer'}} width="1280" height="677" />
                </section>
            </section>
        </main>
    );
}

export default Gites;
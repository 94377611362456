import React, { useState, useEffect, useRef } from "react";
import FAQComponent from './FAQComponent';
import { useLanguage } from "../LanguageContext";
import { useAuth } from "../AuthContext";
import { FaEdit } from 'react-icons/fa';

import "./styles/GitesAround.css";

import gitesBackgroundPng from "../media/png/background/gites_background.png";
import mapBackgroundPng from "../media/png/background/map_background.png";
import videoBackground from "../media/intro.mp4";

const GitesAround = () => {
    const { user } = useAuth();
    const { language } = useLanguage();
    const [editing, setEditing] = useState({ key: null });
    const [editableText, setEditableText] = useState('');
    const [gites, setGites] = useState([]);
    const [gitesAround, setGitesAround] = useState([]);
    const [editingImageId, setEditingImageId] = useState(null);
    const fileInputRef = useRef(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 699);
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleImageClick = () => {
        if (!gites) {
            return;
        }

        const gite = gites[0];        
        const [latitude, longitude] = gite.position;

        const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        window.open(url, '_blank');
    };

    useEffect(() => {
        fetch(`data/${language}/gitesAround.json`)
          .then(response => response.json())
          .then(data => setGitesAround(data.gitesAround))
          .catch(error => console.error('Erreur lors du chargement des gîtes:', error));
      }, [language]);    

      useEffect(() => {
        fetch(`/data/${language}/gites.json`)
          .then(response => response.json())
          .then(data => setGites(data.gites))
          .catch(error => console.error('Erreur lors du chargement des gîtes:', error));
      }, [language]);  

      const handleEdit = (id, type, text) => {
        setEditing({ id, type });
        setEditableText(text);
    };

    const handleSave = async (id, type) => {
    
        const bodyContent = {
            id,
            key: type,
            value: editableText,
            language: language
        };
    
        try {
            const response = await fetch(`${apiUrl}/updateGitesAround`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
    
            setEditing({ key: null });
    
            console.log("Mise à jour réussie");
        } catch (error) {
            console.error("Erreur lors de la mise à jour du gîte :", error);
        }
    };

    const handleImageEdit = (id) => {
        setEditingImageId(id);
        fileInputRef.current.click();
    };

    const handleFileChange = async (event, key) => {
        const file = event.target.files[0];
        if (!file) {
            console.log("Aucun fichier sélectionné.");
            return;
        }
    
        const formData = new FormData();    

        formData.append('id', editingImageId);
        formData.append('key', "image");
        
        formData.append('image', file, file.name);
        formData.append('language', 'all');
    
        try {
            const response = await fetch(`${apiUrl}/updateGitesAround`, {
                method: 'POST',
                body: formData,
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
    
            const responseData = await response.json();
            console.log("Réponse du serveur :", responseData.message);
    
        } catch (error) {
            console.error("Erreur lors de l'envoi de l'image :", error);
        }
    
        event.target.value = null;
    };


    return (
        <main className="gitesAround">
            <section className="gitesAround-section1">
                <video autoPlay loop muted className="gites-video">
                    <source src={videoBackground} type="video/mp4" alt="Vidéo des gîtes" />
                    Votre navigateur ne supporte pas la balise vidéo.
                </video>
            </section>

            <section className="gitesAround-section2">
                <div className="gitesAround-section2-container">
                    {gitesAround.map((giteAround, index) => (
                        <div key={giteAround.id} className={`gitesAround-section2-content ${index % 2 === 0 ? 'reverse' : ''}`}>
                            <img src={giteAround.image} alt="Lieu de la ville" className="gitesAround-section2-image" onClick={() => user && handleImageEdit(giteAround.id)} />
                            {user && <FaEdit className="edit-icon" onClick={() => handleImageEdit(giteAround.id)} />}
                            <div className={`gitesAround-section2-text ${index % 2 === 0 ? 'reverse' : ''}`}>
                                {editing.id === giteAround.id && editing.type === 'city' ? (
                                    <>
                                        <input className="gitesAround-section2-text-edit" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="gitesAround-edit-button" onClick={() => handleSave(giteAround.id, 'city')}>Enregistrer</button>
                                    </>
                                ) : (
                                    <>
                                        <h3>{giteAround.city} {user && <FaEdit onClick={() => handleEdit(giteAround.id, 'city', giteAround.city)} />}</h3>
                                    </>
                                )}
                                {editing.id === giteAround.id && editing.type === 'description' ? (
                                    <>
                                        <textarea className="gitesAround-section2-text-edit-area" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="gitesAround-edit-button" onClick={() => handleSave(giteAround.id, 'description')}>Enregistrer</button>
                                    </>
                                ) : (
                                    <>
                                        <p>{giteAround.description} {user && <FaEdit onClick={() => handleEdit(giteAround.id, 'description', giteAround.description)} />}</p>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                    <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                </div>
            </section>

            <section>
                <FAQComponent />
            </section>

            <section className="gitesAround-section-map">
                <img src={mapBackgroundPng} alt="Localisation des gîtes" onClick={handleImageClick} style={{cursor: 'pointer'}} width="1280" height="677" />
            </section>

        </main>
    );
}

export default GitesAround;
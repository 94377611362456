import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../AuthContext";
import { useLanguage } from "../LanguageContext";
import { FaEdit } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa'; 
import { FaCheck } from 'react-icons/fa'; 
import { FaTrash } from 'react-icons/fa'; 
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./styles/HomePage.css";

import mapBackgroundPng from "../media/png/background/map_background.png";
import videoBackground from "../media/intro.mp4";

const HomePage = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { language } = useLanguage();
    const [services, setServices] = useState([]);
    const [home, setHome] = useState([]);
    const [isAddingService, setIsAddingService] = useState(false);
    const [newServiceText, setNewServiceText] = useState('');
    const [newServiceImage, setNewServiceImage] = useState(null);
    const [gites, setGites] = useState([]);
    const [giteImages, setGiteImages] = useState([]);
    const [editing, setEditing] = useState({ id: null, type: null });
    const [editableText, setEditableText] = useState('');
    const [newPositionsImage, setNewPositionsImage] = useState(0);
    const [servicePosition, setServicePosition] = useState({});
    const fileInputRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    const settings = {
        dots: false, 
        infinite: true, 
        speed: 1000,
        slidesToShow: 1, 
        slidesToScroll: 1,
        autoplay: true, 
        autoplaySpeed: 6000,
        arrows: false,
      };

    useEffect(() => {
        fetch(`/data/${language}/services.json`)
          .then((response) => response.json())
          .then((data) => setServices(data.services))
          .catch((error) => console.error("Erreur de chargement des données:", error));
      }, [language]);

    useEffect(() => {
        fetch(`/data/${language}/home.json`)
            .then((response) => response.json())
            .then((data) => setHome(data.home))
            .catch((error) => console.error("Erreur de chargement des données:", error));
    }, [language]);

    useEffect(() => {
        fetch(`/data/${language}/gites.json`)
          .then(response => response.json())
          .then(data => {
            setGites(data.gites);
            setGiteImages(data.images);
          })
          .catch(error => console.error('Erreur lors du chargement des gîtes:', error));
      }, [language]); 

    const handleEdit = (id, type, text) => {
        setEditing({ id, type });
        setEditableText(text);
    };

    const handleImageEdit = () => { 
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const formData = new FormData();
        formData.append('image', file);    
    
        try {
            const response = await fetch(`${apiUrl}/addGitesImage`, {
                method: 'POST',
                body: formData,
                credentials: "include"
            });
            if (response.ok) {
                await response.json();
            } else {
                console.error('Erreur lors de l\'ajout de l\'image');
            }
        } catch (error) {
            console.error('Erreur lors de l\'envoi du fichier :', error);
        }
    };

    const handleUpdateImagePosition = async (imageUrl) => {
    
        try {
            const response = await fetch(`${apiUrl}/updateImagePosition`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    imageUrl,
                    newPosition: parseInt(newPositionsImage, 10) - 1
                }),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error('Erreur lors de la mise à jour de la position de l\'image');
            }
    
            console.log('Position mise à jour avec succès');
            window.location.reload();  
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error);
        }
    };  

    const handleImageClick = () => {
        if (!gites) {
            return;
        }

        const gite = gites[0];        
        const [latitude, longitude] = gite.position;

        const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        window.open(url, '_blank');
    };

    const handleDeleteImage = async (imagePath) => {
        try {
            const response = await fetch(`${apiUrl}/deleteGitesImage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    value: imagePath
                }),
                credentials: "include"
            });
            if (response.ok) {
                console.log("Mise à jour réussie");
                window.location.reload(); 
            } else {
                console.error('Erreur lors de la suppression de l\'image');
            }
        } catch (error) {
            console.error('Erreur lors de la suppression de l\'image :', error);
        }
    };   

    const handleSaveHomeServiceText = async (id) => {
    
        const bodyContent = {
            id: id,
            text: editableText,
            language: language
        };
    
        try {
            const response = await fetch(`${apiUrl}/updateServiceText`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
    
            setEditing({ id: null, type: null });

            console.log("Mise à jour réussie");
            window.location.reload(); 
        } catch (error) {
            console.error("Erreur lors de la mise à jour du gîte :", error);
        }
    };   

    const handleSaveHomeService = async (type) => {
    
        const bodyContent = {
            key: type,
            value: editableText,
            language: language
        };
    
        try {
            const response = await fetch(`${apiUrl}/updateHomeService`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
    
            setEditing({ id: null, type: null });

            console.log("Mise à jour réussie");
            window.location.reload(); 
        } catch (error) {
            console.error("Erreur lors de la mise à jour du gîte :", error);
        }
    };    

    const handleAddServiceClick = () => {
        setIsAddingService(true);
    };

    const handleServiceTextChange = (e) => {
        setNewServiceText(e.target.value);
    };

    const handleServiceImageChange = (e) => {
        setNewServiceImage(e.target.files[0]);
    };

    const handleConfirmAddService = async () => {
        const formData = new FormData();
        formData.append('text', newServiceText);
        formData.append('language', language);
        if (newServiceImage) {
            formData.append('image', newServiceImage);
        }

        try {
            const response = await fetch(`${apiUrl}/addServiceText`, {
                method: 'POST',
                body: formData,
                credentials: "include"
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            await response.json();

            setIsAddingService(false);
            setNewServiceText('');
            setNewServiceImage(null);
        } catch (error) {
            console.error("Erreur lors de l'ajout d'un service :", error);
        }
    };

    const deleteService = async (id) => {
        try {
            const response = await fetch(`${apiUrl}/deleteServiceText`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: id, language: language }),
                credentials: "include"
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            await response.json();

            setServices(services.filter(service => service.id !== id));
        } catch (error) {
            console.error("Erreur lors de la suppression du service :", error);
        }
    };

    const handlePositionChange = (e, serviceId) => {
        const newPosition = e.target.value;
        setServicePosition(prevPositions => ({
            ...prevPositions,
            [serviceId]: newPosition
        }));
    };

    const updateServicePosition = async (id) => {
        const position = servicePosition[id];
        try {
            const response = await fetch(`${apiUrl}/updateService`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify({ oldId: id, newId: position, language: language }),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            await response.json();
    
            setServices(prevServices => {
                const updatedServices = [...prevServices];

                const index = updatedServices.findIndex(service => service.id === id);
                if (index !== -1) {
                    updatedServices[index] = { ...updatedServices[index], position: servicePosition };
                }
                return updatedServices;
            });
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la position du service :", error);
        }
    };

    return (
        <main className="home">
            <section className="home-section1">
                <div className="home-video-background">
                    <video autoPlay loop muted className='home-video'>
                        <source src={videoBackground} type="video/mp4" alt="Vidéo des gîtes" />
                        Votre navigateur ne supporte pas la balise vidéo.
                    </video>
                </div>      
            </section>  
                
            <section className="home-content">
                <section className="home-section2">
                    <h1>{t("home.title")}</h1>
                    <Slider {...settings} className="home-slider-container">
                        {giteImages && giteImages.map((image, index) => {
                            return (
                            <div key={index} className="home-thumbnail-container">
                                <img
                                src={image}
                                alt="Présentation des gîtes et du domaine"
                                className="home-thumbnail"
                                />
                                {user && (
                                <div className="home-slider-edit">
                                    <p>Position actuelle : {index + 1}</p>
                                    <div className="home-slider-edit-position">
                                    <FaTrash onClick={() => handleDeleteImage(image)} style={{ cursor: 'pointer' }} />
                                    <input
                                        type="number"
                                        placeholder="Nouvelle position"
                                        onChange={(e) => setNewPositionsImage(e.target.value)}
                                    />
                                    <FaCheck onClick={() => handleUpdateImagePosition(image)} style={{ cursor: 'pointer' }} />
                                    </div>
                                </div>
                                )}
                            </div>
                            );
                        })}
                    </Slider>
                    <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                    {user && <button className="home-add-image" onClick={() => handleImageEdit()}>+</button>}
                </section>

                <section className="home-section-services">
                    <div className="home-services-content">
                        {home && (
                            <div className="home-services-text">
                                {editing.type === 'serviceTitle' ? (
                                    <>
                                        <input className="home-section-services-text-edit" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="home-edit-button" onClick={() => handleSaveHomeService('serviceTitle')}>Enregistrer</button>
                                    </>
                                ) : (
                                    <h2 onClick={() => user && handleEdit(0, 'serviceTitle', home.serviceTitle)}>{home.serviceTitle} {user && <FaEdit />}</h2>
                                )}
                                {editing.type === 'serviceBody' ? (
                                    <>
                                        <textarea className="home-section-services-text-edit-area" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="home-edit-button" onClick={() => handleSaveHomeService('serviceBody')}>Enregistrer</button>
                                    </>
                                ) : (
                                    <p onClick={() => user && handleEdit(0, 'serviceBody', home.serviceBody)}>{home.serviceBody} {user && <FaEdit />}</p>
                                )}

                                <Link to="/contact" className="home-services-button">{t("home.contactUs")}</Link>
                            </div>
                        )}
                        <div className="home-services-list">
                        {services.map(service => (
                            <div key={service.id} className="home-service-item">
                                <img src={service.image} alt={service.text} className="home-service-icon" width="40" height="40" />
                                {editing.type === 'serviceText' && editing.id === service.id ? (
                                    <>
                                        <textarea className="home-service-item-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="home-edit-button" onClick={() => handleSaveHomeServiceText(service.id)}>Enregistrer</button>
                                    </>
                                ) : (
                                    <p onClick={() => user && handleEdit(service.id, 'serviceText', service.text)}>{service.text} {user && <FaEdit />}</p>
                                )}
                                {user && (
                                    <>
                                        <FaTrashAlt 
                                            onClick={() => deleteService(service.id)} 
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <label htmlFor={`position-${service.id}`}>Position</label>
                                        <input
                                            className='home-services-input'
                                            id={`position-${service.id}`}
                                            type="number"
                                            value={servicePosition[service.id] || ''}
                                            onChange={(e) => handlePositionChange(e, service.id)}
                                            style={{ marginLeft: '10px', marginRight: '5px' }}
                                        />

                                        <FaCheck 
                                            onClick={() => updateServicePosition(service.id)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </>
                                )}
                            </div>
                        ))}
                        </div>
                        {user && !isAddingService && (
                            <FaPlus onClick={handleAddServiceClick} style={{ cursor: 'pointer' }} />
                        )}
                        {isAddingService && (
                            <div>
                                <input type="text" value={newServiceText} onChange={handleServiceTextChange} placeholder="Texte du service" />
                                <input type="file" onChange={handleServiceImageChange} />
                                <button className="home-edit-button" onClick={handleConfirmAddService}>Confirmer</button>
                            </div>
                        )}
                    </div>
                </section>
                
                <section className="home-section-map">
                    <img src={mapBackgroundPng} alt="Localisation des gîtes" onClick={handleImageClick} style={{cursor: 'pointer'}} width="1280" height="677" />
                </section>
            </section>
        </main>
    );
}

export default HomePage;
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from "../AuthContext";
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { useLanguage } from "../LanguageContext";
import { format } from 'date-fns';
import { enUS, fr, de } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';

import pricesBackgroundPng from "../media/png/background/prices_background.png";
import pricesBandePng from "../media/png/background/prices_bande.png";

import './styles/Prices.css'; 

registerLocale('fr', fr);

const Prices = () => {
    const [stayDuration, setStayDuration] = useState('long');
    const [season, setSeason] = useState('low');
    const { t } = useTranslation();
    const { user } = useAuth();
    const [gites, setGites] = useState([]);
    const [gitePrices, setGitePrices] = useState([]);
    const [isEditingPrice, setIsEditingPrice] = useState(false);
    const [isEditingCaution, setIsEditingCaution] = useState(false);
    const [isEditingWeekDay, setIsEditingWeekDay] = useState(false);
    const [newPrice, setNewPrice] = useState('');
    const [priceType, setPriceType] = useState('');
    const [selectedDay, setSelectedDay] = useState('0');
    const { language } = useLanguage();

    const localeMap = {
        en: enUS,
        fr: fr,
        de: de,
    };

    const currentLocale = localeMap[language] || fr;

    const apiUrl = process.env.REACT_APP_API_URL;

    const seasonToId = {
        low: 1,
        mid: 2,
        high: 3,
        end: 4,
    };
    
    const stayDurationToId = {
        long: 1,
        short: 2,
    };

    const [isEditingDate, setIsEditingDate] = useState(false);
    const [isEditingDate2, setIsEditingDate2] = useState(false);
    const [editingDateInfo, setEditingDateInfo] = useState({
    periodId: null,
    seasonId: null,
    dateNumber: null,
    startDate: '',
    endDate: '',
    });

    const daysOfWeekOptions = Array.from({ length: 7 }).map((_, index) => {
        const day = new Date(2020, 5, index);
        const dayName = format(day, 'EEEE', { locale: currentLocale });
        return <option key={index} value={index}>{dayName}</option>;
    });

    const handleEditDates = (giteId, periodId, seasonId, dateNumber) => {
        if (dateNumber === 1) {
            setIsEditingDate(true);
            setIsEditingDate2(false);
        } else {
            setIsEditingDate2(true);
            setIsEditingDate(false);
        }
        setEditingDateInfo({ ...editingDateInfo, giteId, periodId, seasonId, dateNumber });
    }; 

    const handleSubmitWeekPeriod = async (giteId) => {     

        try {
          const response = await fetch(`${apiUrl}/updateDayOfWeek`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ giteId, dayOfWeek: selectedDay }),
            credentials: "include"
          });
      
          if (!response.ok) {
            throw new Error(`Erreur HTTP: ${response.status}`);
          }
      
          setIsEditingWeekDay(false);
          console.log("Mise à jour réussie");
          window.location.reload();
        } catch (error) {
          console.error("Erreur lors de la mise à jour des dates :", error);
        }
    };
      
    
    const handleUpdateDates = async () => {
        const { giteId, periodId, seasonId, dateNumber, startDate, endDate } = editingDateInfo;
      
        try {
          const response = await fetch(`${apiUrl}/updateDate`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ giteId, periodId, seasonId, dateNumber, startDate, endDate }),
            credentials: "include"
          });
      
          if (!response.ok) {
            throw new Error(`Erreur HTTP: ${response.status}`);
          }
      
          setIsEditingDate(false);
          setIsEditingDate2(false);
          console.log("Mise à jour réussie");
          window.location.reload();
        } catch (error) {
          console.error("Erreur lors de la mise à jour des dates :", error);
        }
    };      

    useEffect(() => {
        const fetchGitePrices = async () => {
            try {
                const response = await fetch(`${apiUrl}/getGitesPrices`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setGitePrices(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des prix des gîtes:", error);
            }
        };

        fetchGitePrices();
    }, [apiUrl]);

    useEffect(() => {
        fetch(`/data/${language}/gites.json`)
          .then(response => response.json())
          .then(data => setGites(data.gites))
          .catch(error => console.error('Erreur lors du chargement des gîtes:', error));
    }, [language]);

    const handleEditPriceClick = (gitePriceDetail) => {
        setIsEditingPrice(gitePriceDetail.giteId);
    };

    const handleEditCautionClick = (gitePriceDetail) => {
        setIsEditingCaution(gitePriceDetail.giteId);
    };

    const handleEditWeekDay = (gitePriceDetail) => {
        setIsEditingWeekDay(gitePriceDetail.giteId);
    };

    const handleSubmitNewPrice = async (giteId, timeId, seasonId) => {
        const updatedPriceInfo = {
          giteId,
          timeId,
          seasonId,
          newPrice,
          priceType
        };
      
        try {
          const response = await fetch(`${apiUrl}/updatePrice`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedPriceInfo),
            credentials: "include"
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          console.log("Prix mis à jour avec succès.");
          setIsEditingPrice(false);
          setIsEditingCaution(false);
          window.location.reload();
        } catch (error) {
          console.error("Erreur lors de la mise à jour du prix :", error);
        }
    };     

    const handlePriceChange = (e, priceType) => {
        const price = e.target.value;
        setNewPrice(price);
        setPriceType(priceType);        
      };
      

    return (
        <main className="prices">
            <section className="prices-section-choice">
                <img src={pricesBackgroundPng} alt="Background" className="prices-bg-top-right" width="293" height="294" />
                <img src={pricesBackgroundPng} alt="Background" className="prices-bg-bottom-left" width="293" height="294" />
                <div className="prices-content">
                    <div className="prices-row">
                        <div className="prices-box">
                            <h2>{t("prices.chooseOptions")}</h2>
                        </div>
                        <div className="prices-middle-box">
                            <div className="prices-option">
                                <h2>{t("prices.duration")}</h2>
                                <div className="prices-option-button">
                                    <button className={stayDuration === 'long' ? 'selected' : 'unselected'} onClick={() => { setStayDuration('long'); }}>{t("prices.longTrip")}</button>
                                    <button className={stayDuration === 'short' ? 'selected' : 'unselected'} onClick={() => { setStayDuration('short'); if (season === 'high' || season === 'end') { setSeason('mid'); } }}>{t("prices.shortTrip")}</button>
                                </div>
                            </div>
                        </div>
                        <div className="prices-end-box">
                            <div className="prices-option">
                                <h2>{t("prices.period")}</h2>
                                <div className="prices-option-button">
                                    <button className={season === 'low' ? 'selected' : 'unselected'} onClick={() => setSeason('low')}>{t("prices.lowSeason")}</button>
                                    <button className={season === 'mid' ? 'selected' : 'unselected'} onClick={() => setSeason('mid')}>{t("prices.mediumSeason")}</button>
                                    <button className={season === 'high' ? 'selected' : 'unselected'} onClick={() => { if (stayDuration !== 'short') { setSeason('high'); } }} disabled={stayDuration === 'short'}>{t("prices.hightSeason")}</button>
                                    <button className={season === 'end' ? 'selected' : 'unselected'} onClick={() => { if (stayDuration !== 'short') { setSeason('end'); } }} disabled={stayDuration === 'short'}>{t("prices.endYear")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="prices-row">
                        {gites.map((gite, index) => {
                            const selectedSeasonId = seasonToId[season];
                            const selectedStayDurationId = stayDurationToId[stayDuration];

                            const gitePriceDetail = gitePrices.find(priceDetail => 
                                priceDetail.giteId === gite.id &&
                                priceDetail.seasonId === selectedSeasonId &&
                                priceDetail.timeId === selectedStayDurationId
                            );

                            const formatPrice = (price) => {
                                const priceNumber = parseFloat(price);
                                if (Number.isInteger(priceNumber)) {
                                    return `${priceNumber}€`;
                                }
                                return `${priceNumber.toFixed(0)}€`;
                            };
                            
                            const gitePrice = gitePriceDetail ? formatPrice(gitePriceDetail.price) : "N.A";    
                            const caution = gitePriceDetail ? formatPrice(gitePriceDetail.caution) : "N.A";   
                            
                            const dateOfTheWeek = new Date();
                            if (gitePriceDetail) {
                                dateOfTheWeek.setDate(dateOfTheWeek.getDate() - dateOfTheWeek.getDay() + gitePriceDetail.weekPeriod);
                            }

                            const weekPeriod = gitePriceDetail ? format(dateOfTheWeek, 'EEEE', { locale: currentLocale }) : "N.A";
                            
                            const formatDate = (dateString) => {
                                const date = new Date(dateString);
                                return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                              };
                          
                            const datesString = gitePriceDetail && gitePriceDetail.date_start1 ? `${formatDate(gitePriceDetail.date_start1)} au ${formatDate(gitePriceDetail.date_end1)}` : '';
                            const secondDatesString = gitePriceDetail && gitePriceDetail.date_start2 ? `<br />${formatDate(gitePriceDetail.date_start2)} au ${formatDate(gitePriceDetail.date_end2)}` : '';
                          
                            return (
                            <div className="prices-box2" key={gite.id}>
                                <img src={pricesBandePng} alt="" className="prices-bande" width="115" height="121"/>

                                {gitePriceDetail && isEditingPrice === gitePriceDetail.giteId ? (
                                    <div>
                                        <input
                                        type="text"
                                        value={newPrice}
                                        onChange={(e) => handlePriceChange(e, 'reservation')}
                                        />
                                        <button onClick={() => handleSubmitNewPrice(gitePriceDetail.giteId, gitePriceDetail.timeId, gitePriceDetail.seasonId)}>Valider</button>
                                    </div>
                                    ) : (
                                    <div>
                                        <span className="prices-bande-text">{gitePrice}</span>
                                        {user && <FaEdit className="prices-bande-text-icon"onClick={() => handleEditPriceClick(gitePriceDetail)} />}
                                    </div>
                                )}

                                <h3>{gite.name}</h3>
                                {user && <FaEdit className="edit-icon" onClick={() => handleEditDates(gitePriceDetail.giteId, gitePriceDetail.timeId, gitePriceDetail.seasonId, 1)} />}
                                {isEditingDate && editingDateInfo.giteId === gite.id && (
                                    <div>
                                        <input
                                            type="date"
                                            value={editingDateInfo.startDate}
                                            onChange={(e) => setEditingDateInfo({ ...editingDateInfo, startDate: e.target.value })}
                                        />
                                        <input
                                            type="date"
                                            value={editingDateInfo.endDate}
                                            onChange={(e) => setEditingDateInfo({ ...editingDateInfo, endDate: e.target.value })}
                                        />
                                        <button onClick={handleUpdateDates}>Valider</button>
                                    </div>
                                )}
                                <p dangerouslySetInnerHTML={{ __html: datesString + secondDatesString }}></p>
                                {user && secondDatesString && <FaEdit className="edit-icon" onClick={() => handleEditDates(gitePriceDetail.giteId, gitePriceDetail.timeId, gitePriceDetail.seasonId, 2)} />}
                                {isEditingDate2 && editingDateInfo.giteId === gite.id && (
                                    <div>
                                        <input
                                            type="date"
                                            value={editingDateInfo.startDate}
                                            onChange={(e) => setEditingDateInfo({ ...editingDateInfo, startDate: e.target.value })}
                                        />
                                        <input
                                            type="date"
                                            value={editingDateInfo.endDate}
                                            onChange={(e) => setEditingDateInfo({ ...editingDateInfo, endDate: e.target.value })}
                                        />
                                        <button onClick={handleUpdateDates}>Valider</button>
                                    </div>
                                )}
                                <p>· {gitePrice}{t("prices.priceByNight")}</p>
                                <p>· {gitePriceDetail ? `${formatPrice(parseFloat(gitePriceDetail.price) * 7)} ${t("prices.priceByWeek")}` : "Prix non disponible"}</p>
                                {/*gitePriceDetail && isEditingWeekDay === gitePriceDetail.giteId ? (
                                    <div>
                                        <select value={selectedDay} onChange={(e) => setSelectedDay(e.target.value)}>
                                            {daysOfWeekOptions}
                                        </select>
                                        <button onClick={() => handleSubmitWeekPeriod(gitePriceDetail.giteId)}>Valider</button>
                                    </div>
                                    ) : (
                                    <div>                                        
                                        {(stayDuration !== 'short') && (<p>· {t("prices.weekPeriodStart")}{weekPeriod}{t("prices.weekPeriodEnd")}{weekPeriod} {user && <FaEdit onClick={() => handleEditWeekDay(gitePriceDetail)} />}</p>)}
                                    </div>
                                )*/}                                

                                {gitePriceDetail && isEditingCaution === gitePriceDetail.giteId ? (
                                    <div>
                                        <input
                                        type="text"
                                        value={newPrice}
                                        onChange={(e) => handlePriceChange(e, 'caution')}
                                        />
                                        <button onClick={() => handleSubmitNewPrice(gitePriceDetail.giteId, gitePriceDetail.timeId, gitePriceDetail.seasonId)}>Valider</button>
                                    </div>
                                    ) : (
                                    <div>
                                        <p>· {caution}{t("prices.caution")} {user && <FaEdit onClick={() => handleEditCautionClick(gitePriceDetail)} />}</p>
                                        
                                    </div>
                                )}
                                <Link to={`/reservation?gite=${gite.id}`} className="prices-book-link">{t("prices.book")}</Link>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Prices;

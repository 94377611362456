import React, { useRef, useState, useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { NavbarContext } from "../NavbarContext";
import { useLanguage } from '../LanguageContext';
import { useTranslation } from "react-i18next";
import { useAuth } from "../AuthContext";

import logoPng from "../media/png/logo/logo.png";
import logoFrench from "../media/png/logo/logo_france.png";
import logoEnglish from "../media/png/logo/logo_england.png";
import logoSpanish from "../media/png/logo/logo_spain.png";
import logoDeutch from "../media/png/logo/logo_german.png";
import logoNetherland from "../media/png/logo/logo_netherland.png";
import languageIcon from "../media/png/logo/multi_language.png";

import "./styles/Navbar.css";

const Navbar = () => {     
    const { language, toggleLanguage } = useLanguage();
    const menuMobileRef = useRef(null);
    const menuMobileIconRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 699);
    const menuLanguageRef = useRef(null);
    const iconLanguageRef = useRef(null);
    const [isLanguageMenuOpen, setLanguageMenuOpen] = useState(false);
    const { navbarActive, setNavbarActive, setScrollPosition } = useContext(NavbarContext);
    const { user } = useAuth();
    const { t } = useTranslation();

    const changeLanguage = (language) => {
        toggleLanguage(language);
        setLanguageMenuOpen(false);
    };

    const handleNavbarToggle = () => {
        if (!navbarActive) {
            const currentScroll = window.scrollY || document.documentElement.scrollTop;
            setScrollPosition(currentScroll);
        }

        setNavbarActive(!navbarActive);
    }

    const resetNavbar = () => {
        setNavbarActive(false);
        setScrollPosition(0);
    }

    const handleCloseMenu = (e) => {
        if(menuLanguageRef.current && !menuLanguageRef.current.contains(e.target) && !iconLanguageRef.current.contains(e.target)) {
            setLanguageMenuOpen(false);
        }
    };

    //Events
    useEffect(() => {
        if(isLanguageMenuOpen) {
            document.addEventListener("click", handleCloseMenu);
        }

        return () => {
            document.removeEventListener("click", handleCloseMenu);
        };
    }, [isLanguageMenuOpen]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 599);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <header className="navbar">
            {!isMobile ? (
            //Navbar on Desktop
            <div className="navbar-desktop">
                <Link to="/" className="navbar-logo">
                    <img src={logoPng} alt="Logo du domaine" className="navbar-logo-image" loading="eager" />
                </Link>

                <nav aria-label="desktop navigation" className="navbar-center-links">
                    <NavLink to="/" className={({ isActive }) => isActive ? "navbar-link active" : "navbar-link"}>{t("navbar.home")}</NavLink>
                    <NavLink to="/gites" className={({ isActive }) => isActive ? "navbar-link active" : "navbar-link"}>{t("navbar.gites")}</NavLink>
                    <NavLink to="/about" className={({ isActive }) => isActive ? "navbar-link active" : "navbar-link"}>{t("navbar.about")}</NavLink>
                    <NavLink to="/contact-us" className={({ isActive }) => isActive ? "navbar-link active" : "navbar-link"}>{t("navbar.contact")}</NavLink>
                    <NavLink to="/prices" className={({ isActive }) => isActive ? "navbar-link active" : "navbar-link"}>{t("navbar.prices")}</NavLink>
                    <NavLink to="/gites-around" className={({ isActive }) => isActive ? "navbar-link active" : "navbar-link"}>{t("navbar.gitesAround")}</NavLink>
                    <NavLink to="/reviews" className={({ isActive }) => isActive ? "navbar-link active" : "navbar-link"}>{t("navbar.reviews")}</NavLink>
                    {user && (<NavLink to="/admin-dashboard" className={({ isActive }) => isActive ? "navbar-link active" : "navbar-link"}>Admin</NavLink>)}
                    <div className="navbar-language-selector-container">
                        <span ref={iconLanguageRef} onClick={() => setLanguageMenuOpen(!isLanguageMenuOpen)}>
                            <img 
                                src={
                                    language === "fr" ? logoFrench :
                                    language === "en" ? logoEnglish :
                                    language === "es" ? logoSpanish :
                                    language === "de" ? logoDeutch :
                                    language === "nl" ? logoNetherland :
                                    languageIcon
                                } 
                                alt="Icon Changer Langue" 
                                className="navbar-language-icon" 
                                loading="eager" 
                                width="33" 
                                height="33" 
                            />
                        </span>
                        {isLanguageMenuOpen && (
                            <div ref={menuLanguageRef} className="navbar-language-selector-dropdown">
                                <div onClick={() => changeLanguage("fr")} className="navbar-language-option">
                                    <img src={logoFrench} alt="Langue Français" className="navbar-flag-icon" loading="lazy" width="33" height="34" />Français
                                </div>
                                <div onClick={() => changeLanguage("en")} className="navbar-language-option">
                                    <img src={logoEnglish} alt="Langue Anglais" className="navbar-flag-icon" loading="lazy" width="33" height="34" />English
                                </div>
                                <div onClick={() => changeLanguage("es")} className="navbar-language-option">
                                    <img src={logoSpanish} alt="Langue Espagnol" className="navbar-flag-icon" loading="lazy" width="33" height="34" />Español
                                </div>
                                <div onClick={() => changeLanguage("de")} className="navbar-language-option">
                                    <img src={logoDeutch} alt="Langue Allemande" className="navbar-flag-icon" loading="lazy" width="33" height="34" />Deutsch
                                </div>
                                <div onClick={() => changeLanguage("nl")} className="navbar-language-option">
                                    <img src={logoNetherland} alt="Langue Néerlandaise" className="navbar-flag-icon" loading="lazy" width="33" height="34" />Nederlands
                                </div>
                            </div>
                        )}
                    </div>

                    {user && (<Link to="/logout" className="navbar-link">Déconnexion</Link>)}
                </nav>

                <Link to="/reservation" className="navbar-link">
                    <button className="navbar-link-button">{t("navbar.reservation")}</button>
                </Link>
            </div>
            ) : (
            <div className="navbar-mobile">
                <div className="navbar-menu-icon" onClick={handleNavbarToggle} ref={menuMobileIconRef}>
                    <svg className={`navbar-hamburger-icon ${navbarActive ? "active" : ""}`} width="100" height="100" viewBox="0 0 100 100">
                        <g filter="url(#a)"><rect width="57" height="9" x="44" y="52" rx="4.5"/><rect width="45" height="9" x="44" y="69" rx="4.5"/><rect width="51" height="9" x="44" y="86" rx="4.5"/></g><defs><filter id="a" width="395" height="343" x="-106" y="-94" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="4"/><feGaussianBlur stdDeviation="75"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_450_2451"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_450_2451" result="shape"/></filter></defs>
                    </svg>
                </div>
                <nav aria-label="mobile navigation" className={`navbar-sidebar ${navbarActive ? "active" : ""}`} ref={menuMobileRef} >
                    <Link onClick={resetNavbar} to="/" className="navbar-sidebar-link">{t("navbar.home")}</Link>
                    <Link onClick={resetNavbar} to="/gites" className="navbar-sidebar-link">{t("navbar.gites")}</Link>
                    <Link onClick={resetNavbar} to="/about" className="navbar-sidebar-link">{t("navbar.about")}</Link>
                    <Link onClick={resetNavbar} to="/contact-us" className="navbar-sidebar-link">{t("navbar.contact")}</Link>
                    <Link onClick={resetNavbar} to="/prices" className="navbar-sidebar-link">{t("navbar.prices")}</Link>
                    <Link onClick={resetNavbar} to="/gites-around" className="navbar-sidebar-link">{t("navbar.gitesAround")}</Link>
                    <Link onClick={resetNavbar} to="/reviews" className="navbar-sidebar-link">{t("navbar.reviews")}</Link>
                    <Link onClick={resetNavbar} to="/reservation" className="navbar-sidebar-link">{t("navbar.mobileReservation")}</Link>
                    <div className="navbar-mobile-language-container">
                        <div onClick={() => changeLanguage("fr")} className="navbar-mobile-language-option">
                            <img src={logoFrench} alt="Langue Français" className="navbar-mobile-french-flag-icon" loading="lazy" width="33" height="34" />
                        </div>

                        <div onClick={() => changeLanguage("en")} className="navbar-mobile-language-option">
                            <img src={logoEnglish} alt="Langue Anglais" className="navbar-mobile-english-flag-icon" loading="lazy" width="33" height="34" />
                        </div>

                        <div onClick={() => changeLanguage("es")} className="navbar-mobile-language-option">
                            <img src={logoSpanish} alt="Langue Espagnol" className="navbar-mobile-english-flag-icon" loading="lazy" width="33" height="34" />
                        </div>

                        <div onClick={() => changeLanguage("de")} className="navbar-mobile-language-option">
                            <img src={logoDeutch} alt="Langue Allemande" className="navbar-mobile-english-flag-icon" loading="lazy" width="33" height="34" />
                        </div>

                        <div onClick={() => changeLanguage("nl")} className="navbar-mobile-language-option">
                            <img src={logoNetherland} alt="Langue Néerlandaise" className="navbar-mobile-english-flag-icon" loading="lazy" width="33" height="34" />
                        </div>
                    </div>
                    {user && (<Link onClick={resetNavbar} to="/logout" className="navbar-sidebar-link">Déconnexion</Link>)}
                </nav>
            </div>
            )}
        </header>        
    );
}

export default Navbar;
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { NavbarProvider } from "./NavbarContext";
import { LanguageProvider } from './LanguageContext';
import { useAuth } from "./AuthContext";
import ProtectedRoutes from "./ProtectedRoutes";

import HomePage from "./components/HomePage";
import Gites from "./components/Gites";
import GitesAround from "./components/GitesAround";
import GiteDetails from "./components/GiteDetails";
import About from "./components/About";
import ContactUs from "./components/ContactUs";
import Reservation from "./components/Reservation";
import PaymentResult from "./components/PaymentResult";
import Prices from "./components/Prices";
import Terms from "./components/Terms";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Reviews from "./components/Reviews";
import LogOutComponent from "./components/LogOut";
import AdminComponent from "./components/Admin";
import ReactGA from "react-ga4";

import ScrollToTop from './ScrollToTop';

function App() {
  const { LogIn, LogOut, setIsLoading } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  React.useEffect(() => {
    ReactGA.initialize([
    {
        trackingId: "G-TY6CTY2GPQ"
      },  
    ]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const verifySession = async () => {
        try {
            //verification session
            const response = await fetch(`${apiUrl}/v`, {
                credentials: "include"
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || "Session non valide ou expirée");
            }
            
            const userId = data.userId;
            LogIn(userId);
            
        } catch (error) {
            LogOut();
        } finally {
            setIsLoading(false);
        }
    };

    verifySession();
}, [LogIn, LogOut, apiUrl, setIsLoading]);

  return (
    <div>
      <LanguageProvider>
          <NavbarProvider>
          <Router>
            <ScrollToTop />
            <Navbar />
            <Routes>
                <Route path="/" element={<HomePage />} />   
                <Route path="/gites" element={<Gites />} />   
                <Route path="/gites/:giteId" element={<GiteDetails />} />
                <Route path="/about" element={<About />} />   
                <Route path="/contact-us" element={<ContactUs />} />   
                <Route path="/reservation" element={<Reservation />} />  
                <Route path="/payment-result" element={<PaymentResult />} /> 
                <Route path="/prices" element={<Prices />} /> 
                <Route path="/gites-around" element={<GitesAround />} /> 
                <Route path="/reviews" element={<Reviews />} />
                <Route path="/terms-of-sale" element={<Terms />} /> 
                <Route path="/logout" element={<LogOutComponent />} />
                <Route path="/admin" element={<AdminComponent />} />

                <Route path="/*" element={<ProtectedRoutes />} />
            </Routes>
            <Footer />
          </Router>
          </NavbarProvider>
        </LanguageProvider>
    </div>
  );
}

export default App;
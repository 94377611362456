import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useAuth } from "../AuthContext";
import { useLanguage } from "../LanguageContext";

import "./styles/Terms.css";

const Terms = () => {
    const { user } = useAuth();
    const { language } = useLanguage();
    const [editing, setEditing] = useState({ key: null });
    const [editableText, setEditableText] = useState('');
    const [terms, setTerms] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetch(`data/${language}/terms.json`)
          .then(response => response.json())
          .then(data => setTerms(data.terms))
          .catch(error => console.error('Erreur lors du chargement des terms:', error));
    }, [language]);  

    const handleEdit = (key, text) => {
        setEditing({ key });
        setEditableText(text);
    };



    const handleSaveModification = async (key, updateLanguage) => {
    
        const bodyContent = {
            key,
            value: editableText,
            language: updateLanguage
        };
    
        try {
            const response = await fetch(`${apiUrl}/updateTerms`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
    
            setEditing({ key: null });
    
            console.log("Mise à jour réussie");
        } catch (error) {
            console.error("Erreur lors de la mise à jour du gîte :", error);
        }
    };

    const handleAddArticle = async (title, body, articleId, updateLanguage) => {
        const bodyContent = {
            title,
            body,
            articleId, 
            language: updateLanguage
        };
    
        try {
            const response = await fetch(`${apiUrl}/addTermsArticle`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
    
            console.log("Article ajouté ou mis à jour avec succès");
        } catch (error) {
            console.error("Erreur lors de l'ajout de l'article :", error);
        }
    };

    const handleDeleteArticle = async (articleId, bodyIndex = undefined, updateLanguage) => {
        const bodyContent = {
            articleId,
            bodyIndex, 
            language: updateLanguage
        };
    
        try {
            const response = await fetch(`${apiUrl}/deleteTermsArticle`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
    
            console.log("Article ou ligne du corps de l'article supprimé avec succès");
        } catch (error) {
            console.error("Erreur lors de la suppression de l'article :", error);
        }
    };
    
    

    return(
        <main className='terms'>  
            
            {/* --------------- Intro -------------------------*/
            editing.key === 'title' ? (
                <>
                    <textarea className="contact-section2-text-edit" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="contact-edit-button" onClick={() => handleSaveModification('title', language)}>Enregistrer</button>
                </>
            ) : (
                <h1 className='terms-title'>
                    {terms.title && terms.title.split('\n').map((line, index, array) => (
                        <React.Fragment key={index}>
                            {line}
                            {index < array.length - 1 && <br />}
                        </React.Fragment>
                    ))}
                    {user && <FaEdit onClick={() => handleEdit('title', terms.title)} />}
                </h1>
            )}
            {editing.key === 'intro' ? (
                <>
                    <textarea className="contact-section2-text-edit" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="contact-edit-button" onClick={() => handleSaveModification('intro', language)}>Enregistrer</button>
                </>
            ) : (
                <p className='terms-intro'>
                    {terms.intro && terms.intro.split('\n').map((line, index, array) => (
                        <React.Fragment key={index}>
                            {line}
                            {index < array.length - 1 && <br />}
                        </React.Fragment>
                    ))}
                    {user && <FaEdit onClick={() => handleEdit('intro', terms.intro)} />}
                </p>
            )}
            
            {/* -------------------------------- Article ---------------------------------*/
                terms.articles && terms.articles.map((article, articleIndex) => (
                    <div className="terms-article" key={articleIndex}>
                        <h2>
                            {
                                editing.key === `${article.id}Title` ? (
                                    <>
                                        <textarea
                                            className="contact-section2-text-edit"
                                            type="text"
                                            value={editableText}
                                            onChange={(e) => setEditableText(e.target.value)}
                                        />
                                        <button className="contact-edit-button" onClick={() => handleSaveModification(`${article.id}Title`, language)}>Enregistrer</button>
                                    </>
                                ) : (
                                    <>
                                        {article.title}
                                        {user && <><FaEdit onClick={() => handleEdit(`${article.id}Title`, article.title)} /><FaTrash onClick={() => handleDeleteArticle(article.id, undefined, language)} /></>}
                                    </>
                                )
                            }
                        </h2>
                        {article.bodies.map((body, bodyIndex) => (
                            <p key={bodyIndex}>
                                {
                                    editing.key === `${article.id}Body${bodyIndex + 1}` ? (
                                        <>
                                            <textarea
                                                className="contact-section2-text-edit"
                                                type="text"
                                                value={editableText}
                                                onChange={(e) => setEditableText(e.target.value)}
                                            />
                                            <button className="contact-edit-button" onClick={() => handleSaveModification(`${article.id}Body${bodyIndex + 1}`, language)}>Enregistrer</button>
                                        </>
                                    ) : (
                                        <>
                                            {body.split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}
                                            {user && <><FaEdit onClick={() => handleEdit(`${article.id}Body${bodyIndex + 1}`, body)} /><FaTrash onClick={() => handleDeleteArticle(article.id, bodyIndex, language)} /></>}
                                        </>
                                    )
                                }
                            </p>
                        ))}
                        {user && (
                            <>
                                {editing.key === `${article.id}` ? (
                                    <>
                                        <textarea
                                            className="contact-section2-text-edit"
                                            type="text"
                                            value={editableText}
                                            onChange={(e) => setEditableText(e.target.value)}
                                        />
                                        <button className="terms-edit-button" onClick={() => handleAddArticle("", editableText, `${article.id}`, language)}>Enregistrer</button>
                                    </>
                                ) : (
                                    <>
                                        <button className="terms-add-line-button" onClick={() => handleEdit(`${article.id}`, "")}>
                                            Ajouter une ligne
                                        </button>
                                    </>
                                )}
                            </>
                        )}                        
                    </div>
                ))
            }
            {user && (
                <div className="add-article-section">
                    {editing.key === "article" ? (
                        <>
                            <textarea
                                className="contact-section2-text-edit"
                                type="text"
                                value={editableText}
                                onChange={(e) => setEditableText(e.target.value)}
                            />
                            <button className="contact-edit-button" onClick={() => handleAddArticle(editableText, "", "", language)}>Enregistrer</button>
                        </>
                    ) : (
                        <>
                            <button className="terms-add-article-button" onClick={() => handleEdit("article", "")}>
                                Ajouter un article
                            </button>
                        </>
                    )}
                </div>
            )}          
            
            {/* ------------------------- Date ---------------------------*/
            editing.key === 'date' ? (
                <>
                    <textarea className="contact-section2-text-edit" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                    <button className="contact-edit-button" onClick={() => handleSaveModification('date', language)}>Enregistrer</button>
                </>
            ) : (
                <h3>{terms.date} {user && <FaEdit onClick={() => handleEdit('date', terms.date)} />}</h3>
            )}
        </main>
    );

};

export default Terms;
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import FAQComponent from './FAQComponent';
import { useTranslation } from "react-i18next";
import { FaEdit } from 'react-icons/fa';
import { useAuth } from "../AuthContext";
import { FaTrash } from 'react-icons/fa'; 
import { FaCheck } from 'react-icons/fa'; 
import { useLanguage } from "../LanguageContext";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./styles/GiteDetails.css";

import mapBackgroundPng from "../media/png/background/map_background.png";

const GiteDetails = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { language } = useLanguage();
    let { giteId } = useParams();
    const [giteDetails, setGiteDetails] = useState(null);
    const [selectedImage, setSelectedImage] = useState();
    const [otherGites, setOtherGites] = useState([]);
    const [gites, setGites] = useState([]);
    const [editing, setEditing] = useState({ id: null, type: null });
    const [editableText, setEditableText] = useState('');
    const [editingImageGiteId, setEditingImageGiteId] = useState(null);
    const [newEquipment, setNewEquipment] = useState('');
    const [addingEquipmentTo, setAddingEquipmentTo] = useState(null);
    const [newPositionsEquipment, setNewPositionsEquipment] = useState(0);
    const [newPositionsScroll, setNewPositionsScroll] = useState(0);
    const fileInputRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const handleImageClick = () => {
        if (!gites) {
            return;
        }

        const gite = gites[0];        
        const [latitude, longitude] = gite.position;

        const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        window.open(url, '_blank');
    };

    useEffect(() => {
        const giteIdInt = parseInt(giteId, 10);
        const gite = gites.find(gite => gite.id === giteIdInt);
        if (gite) { 
          setGiteDetails(gite);
          setSelectedImage(gite.imageScroll[0]);
          const filteredGites = gites.filter(gite => gite.id !== giteIdInt);
          setOtherGites(filteredGites);
        }
      }, [gites, giteId]);
      
      useEffect(() => {
        fetch(`/data/${language}/gites.json`)
          .then(response => response.json())
          .then(data => setGites(data.gites))
          .catch(error => console.error('Erreur lors du chargement des gîtes:', error));
      }, [language]);  

    if (!giteDetails) {
        return <div>{t("giteDetail.loading")}</div>;
    }

    const handleUpdateEquipment = async (equipmentName, container) => {
        const giteId = giteDetails.id;
    
        try {
            const response = await fetch(`${apiUrl}/updateEquipment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    container,
                    giteId,
                    equipment: equipmentName,
                    newPosition: parseInt(newPositionsEquipment, 10) - 1,
                    language: language,
                    newText: editableText
                }),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error('Erreur lors de la mise à jour de la position de l\'équipement');
            }
    
            console.log('Position mise à jour avec succès');
            window.location.reload();  
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error);
        }
    }; 
    
    const handleUpdateScrollPosition = async (imageUrl) => {
        const giteId = giteDetails.id;
    
        try {
            const response = await fetch(`${apiUrl}/updateScrollPosition`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    giteId,
                    imageUrl,
                    newPosition: parseInt(newPositionsScroll, 10) - 1
                }),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error('Erreur lors de la mise à jour de la position de l\'image');
            }
    
            console.log('Position mise à jour avec succès');
            window.location.reload();  
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error);
        }
    };  

    const handleEdit = (id, type, text) => {
        setEditing({ id, type });
        setEditableText(text);
    };

    const handleSave = async (id, type) => {    
        const bodyContent = {
            id,
            key: type,
            value: editableText,
            language: language
        };
    
        try {
            const response = await fetch(`${apiUrl}/updateGites`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            console.log("Mise à jour réussie");

            setEditing({ id: null, type: null });
            setEditableText('');
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error);
        }
    };

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        pauseOnHover: true, 
        arrows: true,
    };  

    const handleReservation = () => {
        const params = new URLSearchParams({
            gite: giteId
        }).toString();

        navigate(`/reservation?${params}`);
    };

    const handleAddEquipment = async () => {
        const bodyContent = {
            id: giteDetails.id,
            key: addingEquipmentTo,
            value: newEquipment,
            language: language
        };
    
        try {
            const response = await fetch(`${apiUrl}/addGites`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            if (addingEquipmentTo === 'equipmentsLeft') {
                setGiteDetails(prevDetails => ({
                    ...prevDetails,
                    equipmentsLeft: [...prevDetails.equipmentsLeft, newEquipment]
                }));
            } else if (addingEquipmentTo === 'equipmentsRight') {
                setGiteDetails(prevDetails => ({
                    ...prevDetails,
                    equipmentsRight: [...prevDetails.equipmentsRight, newEquipment]
                }));
            }
    
            setAddingEquipmentTo(null);
            setNewEquipment('');
    
        } catch (error) {
            console.error("Erreur lors de l'ajout d'un nouvel équipement :", error);
        }
    };
    
    const handleDeleteEquipment = async (id, key, equipment) => {
        const bodyContent = {
            id: giteDetails.id,
            key: key,
            value: equipment,
            language: language
        };
    
        try {
            const response = await fetch(`${apiUrl}/deleteGites`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            setGiteDetails(prevDetails => ({
                ...prevDetails,
                [key]: prevDetails[key].filter(e => e !== equipment)
            }));
    
            console.log("Équipement supprimé avec succès");
        } catch (error) {
            console.error("Erreur lors de la suppression de l'équipement :", error);
        }
    };

    const handleImageEdit = (giteId) => {
        setEditingImageGiteId(giteId); 
        fileInputRef.current.click();
    };
    
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const formData = new FormData();
        formData.append('image', file);
        formData.append('id', editingImageGiteId);
        formData.append('key', 'imageScroll');
        formData.append('language', 'all');        
    
        try {
            const response = await fetch(`${apiUrl}/addGites`, {
                method: 'POST',
                body: formData,
                credentials: "include"
            });
            if (response.ok) {
                await response.json();
            } else {
                console.error('Erreur lors de l\'ajout de l\'image');
            }
        } catch (error) {
            console.error('Erreur lors de l\'envoi du fichier :', error);
        }

        setEditingImageGiteId(null);
    };

    const handleDeleteImage = async (imagePath) => {
        try {
            const response = await fetch(`${apiUrl}/deleteGites`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: giteDetails.id,
                    key: 'imageScroll',
                    value: imagePath,
                    language: "all"
                }),
                credentials: "include"
            });
            if (response.ok) {
                setGiteDetails(prev => ({
                    ...prev,
                    imageScroll: prev.imageScroll.filter(image => image !== imagePath),
                }));
            } else {
                console.error('Erreur lors de la suppression de l\'image');
            }
        } catch (error) {
            console.error('Erreur lors de la suppression de l\'image :', error);
        }
    };        

    return (
        <main className="gite-details">
            <section className="gite-main-section">     
                <div className="gite-main-infos">
                    <div className="gite-details-container">
                        <div className="gite-details-main-image">
                            <img src={selectedImage} alt="Gîte" />
                        </div>
                        <div className="gite-details-bottom">
                            <div className="gite-details-text">
                                <h3>{t("giteDetail.hostMessage")}</h3>
                                {editing.id === giteDetails.id && editing.type === 'hostMessage' ? (
                                    <>
                                        <textarea className="gites-details-text-edit-area" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                        <button className="gites-details-edit-button" onClick={() => handleSave(giteDetails.id, 'hostMessage')}>Enregistrer</button>
                                    </>
                                ) : (
                                    <>
                                        <p>{giteDetails.hostMessage} {user && <FaEdit onClick={() => handleEdit(giteDetails.id, 'hostMessage', giteDetails.hostMessage)} />}</p>
                                    </>
                                )}

                            </div>
                            <div className="gite-details-slider">                            
                                <Slider {...settings} className="gite-details-slider-container">
                                    {giteDetails.imageScroll.map((image, index) => (
                                        <div key={index} className="gite-details-thumbnail-container">
                                            <img
                                                src={image}
                                                alt="Miniature du gîte"
                                                onClick={() => setSelectedImage(image)}
                                                className="gite-details-thumbnail"
                                            />
                                            {user && (
                                                <div className="gite-details-slider-edit">
                                                    <p>Position actuel : {index+1}</p>
                                                    <div className="gite-details-slider-edit-position">
                                                        <FaTrash onClick={() => handleDeleteImage(image)} />
                                                        <input
                                                            type="number"
                                                            placeholder="Position"
                                                            onChange={(e) => setNewPositionsScroll(e.target.value)}
                                                        />
                                                        <FaCheck 
                                                            onClick={() => handleUpdateScrollPosition(image)}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </Slider>
                                
                                <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                            </div>
                            {user && <button className="gite-details-add-image" onClick={() => handleImageEdit(giteDetails.id)}>+</button>}
                        </div>
                    </div>
                    <div className="gite-details-content-container">
                        <div className="gite-details-content">
                            {editing.id === giteDetails.id && editing.type === 'title' ? (
                                <>
                                    <input className="gites-details-text-edit-input" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                    <button className="gites-edit-button" onClick={() => handleSave(giteDetails.id, 'title')}>Enregistrer</button>
                                </>
                            ) : (
                                <h1>
                                    {giteDetails.title} {user && <FaEdit onClick={() => handleEdit(giteDetails.id, 'title', giteDetails.title)} />}
                                </h1>
                            )}

                            <Link to='/prices' className='gite-details-price-link'>{t("giteDetail.seePrices")}</Link>

                            {editing.id === giteDetails.id && editing.type === 'description' ? (
                                <>
                                    <textarea className="gites-details-text-edit-area" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                    <button className="gites-details-edit-button" onClick={() => handleSave(giteDetails.id, 'description')}>Enregistrer</button>
                                </>
                            ) : (
                                <p>
                                    {giteDetails.description.split('\n').map((line, index, array) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            {index < array.length - 1 && <br />}
                                        </React.Fragment>
                                    ))}
                                    {user && <FaEdit onClick={() => handleEdit(giteDetails.id, 'description', giteDetails.description)} />}
                                </p>
                            )}
                            <button onClick={handleReservation}>{t("giteDetail.book")}</button>
                        </div>
                    </div>
                </div>

                <div className="gite-equipments-container">
                    <h2>{t("giteDetail.equipments")}</h2>
                    <div className="gite-details-equipments">
                        <div className="gite-details-equipments-left">
                            <ul>
                                {giteDetails.equipmentsLeft.map((equipment, index) => (
                                    <li key={index}>
                                        {editing.type === 'equipmentsLeftText' && editing.id === equipment ? (
                                            <>
                                                <textarea className="gite-details-equipments-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                                <button className="gite-details-edit-button" onClick={() => handleUpdateEquipment(equipment, 'equipmentsLeft')}>Enregistrer</button>
                                            </>
                                        ) : (
                                            <p onClick={() => user && handleEdit(equipment, 'equipmentsLeftText', equipment)}>
                                            {equipment.split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                {line}
                                                {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}
                                            {user && <FaEdit />}</p>
                                        )}                                        
                                        {user && (
                                            <>
                                                <FaTrash onClick={() => handleDeleteEquipment(giteDetails.id, 'equipmentsLeft', equipment)} />
                                                <input
                                                    type="number"
                                                    placeholder="Nouvelle position"
                                                    onChange={(e) => setNewPositionsEquipment(e.target.value)}
                                                />
                                                <button onClick={() => handleUpdateEquipment(equipment, 'equipmentsLeft')}>Valider</button>
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ul>
                            {user && (
                                <>
                                    <button onClick={() => setAddingEquipmentTo('equipmentsLeft')}>+</button>
                                    {addingEquipmentTo === 'equipmentsLeft' && (
                                        <>
                                            <input type="text" value={newEquipment} onChange={(e) => setNewEquipment(e.target.value)} />
                                            <button onClick={handleAddEquipment}>Ajouter</button>
                                        </>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="gite-details-equipments-right">
                        <ul>
                                {giteDetails.equipmentsRight.map((equipment, index) => (
                                    <li key={index}>
                                        {editing.type === 'equipmentsRightText' && editing.id === equipment ? (
                                            <>
                                                <textarea className="gite-details-equipments-edit" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                                <button className="gite-details-edit-button" onClick={() => handleUpdateEquipment(equipment, 'equipmentsRight')}>Enregistrer</button>
                                            </>
                                        ) : (
                                            <p onClick={() => user && handleEdit(equipment, 'equipmentsRightText', equipment)}>
                                                {equipment.split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                {line}
                                                {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}
                                            {user && <FaEdit />}</p>
                                        )}
                                        {user && (
                                            <>
                                                <FaTrash onClick={() => handleDeleteEquipment(giteDetails.id, 'equipmentsRight', equipment)} />
                                                <input
                                                    type="number"
                                                    placeholder="Nouvelle position"
                                                    onChange={(e) => setNewPositionsEquipment(e.target.value)}
                                                />
                                                <button onClick={() => handleUpdateEquipment(equipment, 'equipmentsRight')}>Valider</button>
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ul>
                            {user && (
                                <>
                                    <button onClick={() => setAddingEquipmentTo('equipmentsRight')}>+</button>
                                    {addingEquipmentTo === 'equipmentsRight' && (
                                        <>
                                            <input type="text" value={newEquipment} onChange={(e) => setNewEquipment(e.target.value)} />
                                            <button onClick={handleAddEquipment}>Ajouter</button>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <section className="gite-details-other">
                <h2>{t("giteDetail.otherGites")}</h2>
                <div className="gite-details-other-container">
                    {otherGites.map((gite) => (
                        <Link to={`/gites/${gite.id}`} key={gite.id} className="other-gite-link">
                            <div key={gite.id} className="gite-details-other-gite-item">
                                <img src={gite.imageScroll[0]} alt="Autres gîtes" />
                                <h3>{gite.name}</h3>
                                <Link to='/prices' className='gite-details-price-link-other'>{t("giteDetail.seePrices")}</Link>
                            </div>
                        </Link>
                    ))}
                </div>
            </section>

            <section>
                <FAQComponent />
            </section>

            <section className="gite-details-section-map">
                <img src={mapBackgroundPng} alt="Localisation des gîtes" onClick={handleImageClick} style={{cursor: 'pointer'}} width="1280" height="677" />
            </section>

        </main>
    );
};

export default GiteDetails;

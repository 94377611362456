import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../LanguageContext";
import { FaEdit } from 'react-icons/fa';
import { useAuth } from "../AuthContext";

import "./styles/About.css";

import mapBackgroundPng from "../media/png/background/map_background.png";
import videoBackground from "../media/intro.mp4";

const About = () => {
    const { t } = useTranslation();
    const [aboutText, setAboutText] = useState([]);
    const { language } = useLanguage();
    const { user } = useAuth();
    const [editing, setEditing] = useState({ id: null, type: null });
    const [editableText, setEditableText] = useState('');
    const [gites, setGites] = useState([]);
    const [editingImageKey, setEditingImageKey] = useState(null);
    const fileInputRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleImageClick = () => {
        if (!gites) {
            return;
        }

        const gite = gites[0];        
        const [latitude, longitude] = gite.position;

        const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        window.open(url, '_blank');
    };

    useEffect(() => {
        fetch(`data/${language}/gites.json`)
            .then(response => response.json())
            .then(data => setGites(data.gites))
            .catch(error => console.error('Erreur lors du chargement des gîtes:', error));
    }, [language]);

    useEffect(() => {
        fetch(`/data/${language}/about.json`)
          .then(response => response.json())
          .then(data => setAboutText(data.about))
          .catch(error => console.error('Erreur lors du chargement des gîtes:', error));
      }, [language]);

    const handleEdit = (id, type, text) => {
        setEditing({ id, type });
        setEditableText(text);
    };

    const handleSave = async (id, type) => {
        console.log(`Saving ${type} for gite ${id} with text: ${editableText}`);
    
        const bodyContent = {
            id,
            key: type,
            value: editableText,
            language: language
        };
    
        try {
            const response = await fetch(`${apiUrl}/updateAbout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
    
            setEditing({ id: null, type: null });
    
            console.log("Mise à jour réussie");
        } catch (error) {
            console.error("Erreur lors de la mise à jour du gîte :", error);
        }
    };
    
    const handleImageEdit = (key) => {
        setEditingImageKey(key);
        fileInputRef.current.click();
    };

    const handleFileChange = async (event, key) => {
        const file = event.target.files[0];
        if (!file) {
            console.log("Aucun fichier sélectionné.");
            return;
        }
    
        const formData = new FormData();    

        formData.append('key', key);
        
        formData.append('image', file, file.name);
        formData.append('language', 'all');
    
        try {
            const response = await fetch(`${apiUrl}/updateAbout`, {
                method: 'POST',
                body: formData,
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
    
            const responseData = await response.json();
            console.log("Réponse du serveur :", responseData.message);
    
        } catch (error) {
            console.error("Erreur lors de l'envoi de l'image :", error);
        }
    
        event.target.value = null;
    };

    return (
        <main className="about">
            <section className="about-section1">
                <div className="about-video-background">
                    <video autoPlay loop muted className="about-video">
                        <source src={videoBackground} type="video/mp4" alt="Vidéo du domaine" />
                        Votre navigateur ne supporte pas la balise vidéo.
                    </video>
                </div> 
            </section> 

            <section className="about-content">
                <section className="about-section-slide">
                    <div className="about-slide">
                        <p>{t("about.slogan")} <span>{t("about.slogan")}</span><span>{t("about.slogan")}</span><span>{t("about.slogan")}</span></p>
                    </div>
                </section>

                <section className="about-section2">
                    <img src={aboutText.missionImage} alt="Hôte du domaine" className="about-section2-image" onClick={user ? () => handleImageEdit('missionImage') : undefined}/>
                    {user && <FaEdit className="edit-icon" onClick={() => handleImageEdit('missionImage')} />}
                    <div className="about-section2-text">
                        {editing.type === 'missionTitle' ? (
                            <>
                                <textarea className="about-section2-text-edit-area" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                <button className="about-edit-button" onClick={() => handleSave('mission', 'missionTitle')}>Enregistrer</button>
                            </>
                        ) : (
                            <>
                                <h2>{aboutText.missionTitle} {user && <FaEdit onClick={() => handleEdit('mission', 'missionTitle', aboutText.missionTitle)} />}</h2>
                            </>
                        )}
                        {editing.type === 'missionContent' ? (
                            <>
                                <textarea className="about-section2-text-edit-area" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                <button className="about-edit-button" onClick={() => handleSave('mission', 'missionContent')}>Enregistrer</button>
                            </>
                        ) : (
                            <>
                                <p>{aboutText.missionContent} {user && <FaEdit onClick={() => handleEdit('mission', 'missionContent', aboutText.missionContent)} />}</p>
                            </>
                        )}
                        <Link to="/reservation" className="about-section2-button">{t("about.book")}</Link>
                    </div>
                </section>

                <section className="about-section3">
                    <div className="about-section3-button-container">
                        <Link to="/reservation" className="about-section3-button">{t("about.disponibility")}</Link>
                    </div>
                    <div className="about-section3-text-container">
                        <img src={aboutText.disponibilityImage} alt="Vue extérieur du domaine" className="about-section3-image" onClick={user ? () => handleImageEdit('disponibilityImage') : undefined} />
                        {user && <FaEdit className="edit-icon" onClick={() => handleImageEdit('disponibilityImage')} />}
                        {editing.type === 'disponibilityContent' ? (
                            <>
                                <textarea className="about-section2-text-edit-area" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                <button className="about-edit-button" onClick={() => handleSave('disponibility', 'disponibilityContent')}>Enregistrer</button>
                            </>
                        ) : (
                            <>
                                <p>{aboutText.disponibilityContent} {user && <FaEdit onClick={() => handleEdit('disponibility', 'disponibilityContent', aboutText.disponibilityContent)} />}</p>
                            </>
                        )}
                    </div>
                </section>
                <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={(e) => handleFileChange(e, editingImageKey)} />

                <section className="about-section4">
                    {user && (
                            <FaEdit className="edit-icon" onClick={() => handleImageEdit('externWallImage')} />
                    )}
                    <img src={aboutText.hostMessageImage} alt="Chambre d'un gîte" className="about-section4-image" onClick={() => user && handleImageEdit('hostMessageImage')} />       
                    <div className="about-section4-text-container">
                        {editing.type === 'hostMessageTitle' ? (
                            <>
                                <textarea className="about-section4-text-edit-area" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                <button className="about-section4-edit-button" onClick={() => handleSave('hostMessage', 'hostMessageTitle')}>Enregistrer</button>
                            </>
                        ) : (
                            <>
                                <h3>{aboutText.hostMessageTitle} {user && <FaEdit onClick={() => handleEdit('hostMessage', 'hostMessageTitle', aboutText.hostMessageTitle)} />}</h3>
                            </>
                        )}
                        {editing.type === 'hostMessageContent' ? (
                            <>
                                <textarea className="about-section4-text-edit-area" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                                <button className="about-section4-edit-button" onClick={() => handleSave('hostMessage', 'hostMessageContent')}>Enregistrer</button>
                            </>
                        ) : (
                            <>
                                <p>{aboutText.hostMessageContent} {user && <FaEdit onClick={() => handleEdit('hostMessage', 'hostMessageContent', aboutText.hostMessageContent)} />}</p>
                            </>
                        )}
                    </div>
                </section>

                <section className="about-section-map">
                    <img src={mapBackgroundPng} alt="Localisation des gîtes" onClick={handleImageClick} style={{cursor: 'pointer'}} width="1280" height="677" />
                </section>
            </section>
        </main>
    );
}

export default About;
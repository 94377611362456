import React, { useState, useEffect, forwardRef } from "react";
import { useNavigate } from 'react-router-dom';
import FAQComponent from './FAQComponent';
import { useTranslation } from "react-i18next";
import { useLanguage } from "../LanguageContext";
import { useAuth } from "../AuthContext";
import { FaEdit } from 'react-icons/fa';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr'; 

import 'react-datepicker/dist/react-datepicker.css';

import "./styles/ContactUs.css";

import gitesBackgroundPng from "../media/png/background/gites_background.png";
import mapBackgroundPng from "../media/png/background/map_background.png";

registerLocale('fr', fr);

const ContactUs = () => {
    const [selectedGite, setSelectedGite] = useState('');
    const [arrivalDate, setArrivalDate] = useState('');
    const [departureDate, setDepartureDate] = useState('');
    const { user } = useAuth();
    const { language } = useLanguage();
    const [contact, setContact] = useState([]);
    const [gites, setGites] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [editing, setEditing] = useState({ key: null });
    const [editableText, setEditableText] = useState('');

    const [unavailableStartDates, setUnavailableStartDates] = useState([]);
    const [unavailableEndDates, setUnavailableEndDates] = useState([]);
    const [reservationsDates, setReservationsDates] = useState([]);
    const [minArrivalDate, setMinArrivalDate] = useState(new Date());
    const [maxDepartureDate, setMaxDepartureDate] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 699);
    const apiUrl = process.env.REACT_APP_API_URL;

    const CustomArrivalInputComponent = forwardRef(({ value, onClick, disabled }, ref) => (
        <button 
            className={`contact-section1-dates-calendar ${disabled ? 'disabled' : ''}`} 
            onClick={onClick} 
            ref={ref} 
            disabled={disabled} 
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }} 
        >
            {value || t("reservation.arrivalDate")}
        </button>
    ));

    const CustomDepartureInputComponent = forwardRef(({ value, onClick, disabled }, ref) => (
        <button 
            className={`contact-section1-dates-calendar ${disabled ? 'disabled' : ''}`} 
            onClick={onClick} 
            ref={ref} 
            disabled={disabled}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        >
            {value || t("reservation.departureDate")}
        </button>
    ));

    const validateInput = (name, email, message) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        
        if (!name || !email || !message) {
            alert(t("contact.fieldEmpty"));
            return false;
        }

        if (!emailRegex.test(email)) {
            alert(t("contact.wrongEmail"));
            return false;
        }

        return true
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const name = e.target.name.value;
        const email = e.target.email.value;
        const message = e.target.message.value;

        const isInputValid = validateInput(name, email, message);
        if(!isInputValid) return;

        try {
            const bodyContent = {
                name: name,
                email: email,
                message: message
            };

            const response = await fetch(`${apiUrl}/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
 
            if (response.ok) {
                alert(t("contactUs.sendOk"));
            }
            else {
                alert(t("contactUs.sendNotOk"));
            }              
        }
        catch(error) {
            if (process.env.NODE_ENV !== "production") {
                console.error("Error to send message:", error);
            }
        }        
    };

    const handleImageClick = () => {
        if (!gites) {
            return;
        }

        const gite = gites[0];        
        const [latitude, longitude] = gite.position;

        const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        window.open(url, '_blank');
    };

    useEffect(() => {
        const fetchUnavailableDates = async () => {
            try {
                const response = await fetch(`${apiUrl}/UnavailableDate/${selectedGite}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                const { reservations, seasonPeriods, weekPeriod } = data;
    
                const getDatesInRange = (startDate, endDate, excludeDays = [], excludeStart = false) => {
                    const start = new Date(startDate);
                    const end = new Date(endDate);
                    const dates = [];
    
                    const adjustedStart = excludeStart ? new Date(start.setDate(start.getDate() + 1)) : start;

                    for (let date = new Date(adjustedStart); date <= end; date.setDate(date.getDate() + 1)) {
                        if (!excludeDays.includes(date.getDay())) {
                            dates.push(new Date(date).toISOString().split('T')[0]);
                        }
                    }
    
                    return dates;
                };
    
                let allReservationStartDates = [];
                reservations.forEach(reservation => {
                    const dates = getDatesInRange(reservation.arrival_date, reservation.departure_date);
                    allReservationStartDates = allReservationStartDates.concat(dates);
                });

                let allReservationEndDates = [];
                reservations.forEach(reservation => {
                    const dates = getDatesInRange(reservation.arrival_date, reservation.departure_date, [], true);
                    allReservationEndDates = allReservationEndDates.concat(dates);
                });
                
                let allSeasonDates = [];
                seasonPeriods.forEach(period => {
                    if (period.date_start1 && period.date_end1) {
                        const dates = getDatesInRange(period.date_start1, period.date_end1, [weekPeriod]);
                        allSeasonDates = allSeasonDates.concat(dates);
                    }
                    if (period.date_start2 && period.date_end2) {
                        const dates = getDatesInRange(period.date_start2, period.date_end2, [weekPeriod]);
                        allSeasonDates = allSeasonDates.concat(dates);
                    }
                });
    
                const combinedStartDates = [...new Set([...allReservationStartDates, ...allSeasonDates])]; 
                const combinedEndDates = [...new Set([...allReservationEndDates, ...allSeasonDates])]; 
    
                setUnavailableStartDates(combinedStartDates);
                setUnavailableEndDates(combinedEndDates);
                setReservationsDates(allReservationStartDates);

            } catch (error) {
                console.error("Erreur lors de la récupération des dates indisponibles:", error);
            }
        };
    
        if (selectedGite) {
            fetchUnavailableDates();
        }
    }, [selectedGite, apiUrl]);
    
    useEffect(() => {
        if (arrivalDate) {
            const firstUnavailableAfterArrival = reservationsDates
                .map(date => new Date(date))
                .sort((a, b) => a - b)
                .find(date => date > arrivalDate);

            if (firstUnavailableAfterArrival) {
                const dayBefore = new Date(firstUnavailableAfterArrival);
                dayBefore.setDate(dayBefore.getDate() - 0);
                setMaxDepartureDate(dayBefore);
            } else {
                setMaxDepartureDate(null);
            }
        }
    }, [arrivalDate, reservationsDates]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 599);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleReservation = () => {
        const params = new URLSearchParams();
    
        if (selectedGite) params.append('gite', selectedGite);
        if (arrivalDate) {
            const formatDate = (date) => {
                const d = new Date(date);
                let month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
        
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
        
                return [year, month, day].join('-');
            };
        
            const formattedArrivalDate = formatDate(arrivalDate);
            params.append('arrival', formattedArrivalDate);
        }
    
        if (departureDate) {
            const formatDate = (date) => {
                const d = new Date(date);
                let month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
        
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
        
                return [year, month, day].join('-');
            };
        
            const formattedDepartureDate = formatDate(departureDate);
            params.append('departure', formattedDepartureDate);
        }
    
        const queryString = params.toString();
        navigate(`/reservation${queryString ? `?${queryString}` : ''}`);
    };

    useEffect(() => {
        fetch(`data/${language}/contact.json`)
          .then(response => response.json())
          .then(data => setContact(data.contact))
          .catch(error => console.error('Erreur lors du chargement des gîtes:', error));
      }, [language]);    

      useEffect(() => {
        fetch(`/data/${language}/gites.json`)
          .then(response => response.json())
          .then(data => setGites(data.gites))
          .catch(error => console.error('Erreur lors du chargement des gîtes:', error));
      }, [language]);  

    const handleEdit = (key, text) => {
        setEditing({ key });
        setEditableText(text);
    };

    const handleSave = async (key, updateLanguage) => {
    
        const bodyContent = {
            key,
            value: editableText,
            language: updateLanguage
        };
    
        try {
            const response = await fetch(`${apiUrl}/updateContact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
    
            setEditing({ key: null });
    
            console.log("Mise à jour réussie");
        } catch (error) {
            console.error("Erreur lors de la mise à jour du gîte :", error);
        }
    };

    const handleArrivalDateChange = (date) => {
        setArrivalDate(date);
        setDepartureDate('');
    };

    return (
        <main className="contact">
            <section className="contact-section1">
                <img src={gitesBackgroundPng} alt="Campagne vu de haut" loading="lazy" width="1920" height="520" />
                <div className="contact-section1-reservation-container">
                    {!isMobile && (
                        <select className="contact-section1-gites-selection"
                            value={selectedGite}
                            onChange={(e) => setSelectedGite(e.target.value)}
                        >
                            <option value="">{t("contactUs.selection")}</option>
                            {gites.map((gite) => (
                                <option key={gite.id} value={gite.id}>
                                    {gite.name}
                                </option>
                            ))}
                        </select>
                    )}

                    {!isMobile && (
                        <ReactDatePicker
                            selected={arrivalDate}
                            onChange={handleArrivalDateChange}
                            excludeDates={unavailableStartDates}
                            minDate={minArrivalDate}
                            disabled={!selectedGite}
                            locale="fr"
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Date d'arrivée"
                            className={`contact-section1-dates-calendar ${!selectedGite ? 'disabled' : ''}`}
                            customInput={<CustomArrivalInputComponent />}
                        />
                    )}

                    {!isMobile && (
                        <ReactDatePicker
                        selected={departureDate}
                        onChange={date => setDepartureDate(date)}
                        excludeDates={unavailableEndDates}
                        minDate={arrivalDate ? new Date(new Date(arrivalDate).setDate(new Date(arrivalDate).getDate() + 1)) : new Date()}
                        maxDate={maxDepartureDate}
                        disabled={!selectedGite || !arrivalDate}
                        locale="fr"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Date de départ"
                        className={`contact-section1-dates-calendar ${!selectedGite || !arrivalDate ? 'disabled' : ''}`}
                        customInput={<CustomDepartureInputComponent />}
                    />
                    )}

                    <button className="contact-section1-reservation-button" onClick={handleReservation}>{t("contactUs.book")}</button>
                </div>
            </section>

            <section className="contact-section2">
                <h1>{t("contactUs.contactUs")}</h1>
                <div className="contact-section2-presentation">
                    {editing.key === 'title' ? (
                        <>
                            <input className="contact-section2-text-edit" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="contact-edit-button" onClick={() => handleSave('title', language)}>Enregistrer</button>
                        </>
                    ) : (
                        <h2>{contact.title} {user && <FaEdit onClick={() => handleEdit('title', contact.title)} />}</h2>
                    )}
                    {editing.key === 'body' ? (
                        <>
                            <input className="contact-section2-text-edit-area" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="contact-edit-button" onClick={() => handleSave('body', language)}>Enregistrer</button>
                        </>
                    ) : (
                        <p>{contact.body} {user && <FaEdit onClick={() => handleEdit('body', contact.body)} />}</p>
                    )}
                </div>
                <div className="contact-section2-content">
                    <div className="contact-section2-info">                    
                    {editing.key === 'address1' ? (
                        <>
                            <input className="contact-section2-text-edit" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="contact-edit-button" onClick={() => handleSave('address1', 'all')}>Enregistrer</button>
                        </>
                    ) : (
                        <p className="contact-section2-address">{contact.address1} {user && <FaEdit onClick={() => handleEdit('address1', contact.address1)} />}</p>
                    )}

                    {editing.key === 'address2' ? (
                        <>
                            <input className="contact-section2-text-edit" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="contact-edit-button" onClick={() => handleSave('address2', 'all')}>Enregistrer</button>
                        </>
                    ) : (
                        <p className="contact-section2-address">{contact.address2} {user && <FaEdit onClick={() => handleEdit('address2', contact.address2)} />}</p>
                    )}

                    {editing.key === 'address3' ? (
                        <>
                            <input className="contact-section2-text-edit" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="contact-edit-button" onClick={() => handleSave('address3', 'all')}>Enregistrer</button>
                        </>
                    ) : (
                        <p className="contact-section2-address">{contact.address3} {user && <FaEdit onClick={() => handleEdit('address3', contact.address3)} />}</p>
                    )}

                    {editing.key === 'email' ? (
                        <>
                            <input className="contact-section2-text-edit" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="contact-edit-button" onClick={() => handleSave('email', 'all')}>Enregistrer</button>
                        </>
                    ) : (
                        <p>{t("contactUs.ourEmail")}{contact.email} {user && <FaEdit onClick={() => handleEdit('email', contact.email)} />}</p>
                    )}

                    <p className="contact-section2-phone">{t("contactUs.phone")}</p>
                    {editing.key === 'phone' ? (
                        <>
                            <input className="contact-section2-text-edit" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="contact-edit-button" onClick={() => handleSave('phone', 'all')}>Enregistrer</button>
                        </>
                    ) : (
                        <p className="contact-section2-phone">{contact.phone} {user && <FaEdit onClick={() => handleEdit('phone', contact.phone)} />}</p>
                    )}

                    {editing.key === 'phone2' ? (
                        <>
                            <input className="contact-section2-text-edit" type="text" value={editableText} onChange={(e) => setEditableText(e.target.value)} />
                            <button className="contact-edit-button" onClick={() => handleSave('phone2', 'all')}>Enregistrer</button>
                        </>
                    ) : (
                        <p className="contact-section2-phone">{contact.phone2} {user && <FaEdit onClick={() => handleEdit('phone2', contact.phone2)} />}</p>
                    )}

                    </div>
                    <div className="contact-section2-form">
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="name">{t("contactUs.name")}</label>
                            <input type="text" id="name" name="name" required />
                            
                            <label htmlFor="email">{t("contactUs.email")}</label>
                            <input type="email" id="email" name="email" required />
                            
                            <label htmlFor="message">{t("contactUs.message")}</label>
                            <textarea id="message" name="message" required></textarea>
                            
                            <button type="submit">{t("contactUs.send")}</button>
                        </form>
                    </div>
                </div>
            </section>

            <section>
                <FAQComponent />
            </section>

            <section className="contact-section-map">
                <img src={mapBackgroundPng} alt="Localisation des gîtes" onClick={handleImageClick} style={{cursor: 'pointer'}} width="1280" height="677" />
            </section>

        </main>
    );
}

export default ContactUs;
import React, { useState, useEffect } from 'react';
import './styles/FAQComponent.css';
import { useAuth } from "../AuthContext";
import { FaPlus } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa'; 
import { FaCheck } from 'react-icons/fa'; 
import { useLanguage } from "../LanguageContext";
import { useTranslation } from "react-i18next";

import topArrow from "../media/png/icones/top_arrow.png";
import underArrow from "../media/png/icones/bottom_arrow.png";

const FAQComponent = () => {
    const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
    const [faqs, setFaqs] = useState([]);
    const [newQuestion, setNewQuestion] = useState('');
    const [newAnswer, setNewAnswer] = useState('');
    const [addingFaq, setAddingFaq] = useState(false);
    const [faqPosition, setFaqPosition] = useState({});
    const { user } = useAuth();
    const { language } = useLanguage();
    const { t } = useTranslation();

    const apiUrl = process.env.REACT_APP_API_URL;

    const toggleQuestion = (index) => {
        setOpenQuestionIndex(openQuestionIndex === index ? null : index);
    };

    useEffect(() => {
        fetch(`/data/${language}/faq.json`)
          .then(response => response.json())
          .then(data => setFaqs(data.faq))
          .catch(error => console.error('Erreur lors du chargement des faqs:', error));
    }, [language]);  
 
    const handleAddFaq = async () => {
        const bodyContent = {
            question: newQuestion,
            answer: newAnswer,
            language: language
        };
    
        try {
            const response = await fetch(`${apiUrl}/addFaq`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                const responseData = await response.json();
                console.log("Nouvelle entrée FAQ ajoutée avec succès:", responseData);
                window.location.reload();    
            }
        } catch (error) {
            console.error("Erreur lors de l'ajout de la nouvelle entrée FAQ :", error);
        } finally {
            setAddingFaq(false);
            setNewQuestion('');
            setNewAnswer('');
        }
    };

    const handleDeleteFaq = async (id) => {
        const bodyContent = {
            id: id,
            language: language
        };
        try {
            const response = await fetch(`${apiUrl}/deleteFaq`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            setFaqs(faqs.filter(faq => faq.id !== id));
            
            console.log("Entrée FAQ supprimée avec succès");
        } catch (error) {
            console.error("Erreur lors de la suppression de l'entrée FAQ :", error);
        }
    };  

    const handlePositionChange = (e, faqId) => {
        const newPosition = e.target.value;
        setFaqPosition(prevPositions => ({
            ...prevPositions,
            [faqId]: newPosition
        }));
    };

    const updateFaqPosition = async (id) => {
        const position = faqPosition[id];
        try {
            const response = await fetch(`${apiUrl}/updateFaq`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify({ oldId: id, newId: position, language: language }),
                credentials: "include"
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            await response.json();
    
            setFaqPosition(prevFaq => {
                const updatedFaq = [...prevFaq];

                const index = updatedFaq.findIndex(faq => faq.id === id);
                if (index !== -1) {
                    updatedFaq[index] = { ...updatedFaq[index], position: faqPosition };
                }
                return updatedFaq;
            });
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la position de la faq :", error);
        }
    };

    return (
        <div className="faq-container">
            <h2>{t("faq.question")} <br />{t("faq.frequence")}</h2>
            <div className="faq-item-container">
                {faqs.map((faq, index) => (
                    <div key={faq.id} className="faq-item">
                        <button className="faq-question" onClick={() => toggleQuestion(index)}>
                            {faq.question.split('\n').map((line, index, array) => (
                                <React.Fragment key={index}>
                                {line}
                                {index < array.length - 1 && <br />}
                                </React.Fragment>
                            ))}                
                            <span className="faq-icon">
                                {openQuestionIndex === index ? (
                                    <img src={topArrow} alt="Flèche de monté" width="36" height="36" />
                                ) : (
                                    <img src={underArrow} alt="Flèche de descente" width="36" height="36" />
                                )}
                            </span>
                        </button>
                        {openQuestionIndex === index && <p className="faq-answer">
                            {faq.answer.split('\n').map((line, index, array) => (
                                <React.Fragment key={index}>
                                {line}
                                {index < array.length - 1 && <br />}
                                </React.Fragment>
                            ))} 
                        </p>}
                        {user && (
                            <>
                                <FaTrash onClick={() => handleDeleteFaq(faq.id)} />
                                <label htmlFor={`position-${faq.id}`}>Position</label>
                                <input
                                    className='faq-input'
                                    id={`position-${faq.id}`}
                                    type="number"
                                    value={faqPosition[faq.id] || ''}
                                    onChange={(e) => handlePositionChange(e, faq.id)}
                                    style={{ marginLeft: '10px', marginRight: '5px' }}
                                />

                                <FaCheck 
                                    onClick={() => updateFaqPosition(faq.id)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </>
                        )}
                    </div>
                ))}
                {user && <FaPlus onClick={() => setAddingFaq(true)} style={{ cursor: 'pointer' }} />}
                {addingFaq && (
                    <div>
                        <textarea className="faq-text-edit-input" type="text" placeholder="Nouvelle question" value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)} />
                        <textarea className="faq-text-edit-input" type="text" placeholder="Nouvelle réponse" value={newAnswer} onChange={(e) => setNewAnswer(e.target.value)} />
                        <button className="faq-edit-button" onClick={handleAddFaq}>Valider</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FAQComponent;

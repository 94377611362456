import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./styles/PaymentResult.css";

const PaymentResult = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const reservationId = queryParams.get('reservationId');
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const { t } = useTranslation();
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const confirmReservation = async () => {
            try {
                const response = await fetch(`${apiUrl}/confirm-reservation`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ reservationId }),
                });
                await response.json();
                setIsPaymentLoading(true);
            } catch (error) {
                console.error("Erreur lors de la confirmation de la réservation:", error);
            }
        };

        if (reservationId) {
            confirmReservation();
        }
    }, [apiUrl, reservationId]);

    return (
        <main className='payment-result'>
            <h1>{t("payment-result.title")}</h1>
            {isPaymentLoading ? (
                <p>{t("payment-result.success")}</p>
            ) : (
                <p>{t("payment-result.fail")}</p>
            )}
            <Link to="/">{t("payment-result.back")}</Link>
        </main>
    );
};

export default PaymentResult;

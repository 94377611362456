import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/Reviews.css';

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/data/reviews.json')
      .then(response => response.json())
      .then(data => {
        setReviews(data);
        setLoading(false); 
      })
      .catch(error => {
        console.error('Error fetching the reviews:', error);
        setLoading(false); 
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>; 
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "20px"
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "30px",
          arrows: false
        }
      }
    ]
  };

  return (
    <div className="reviews-wrapper">
      <h2>Ce que les clients disent de nous</h2>
      <Slider {...settings}>
            {reviews.map((review, index) => (
            <div key={index} className="reviews-card-wrapper">
                <div className="reviews-card">
                  <div className="reviews-card-header">
                      <div className="reviews-card-info">
                        <h3>{review.name}</h3>
                        <div className="reviews-card-rating">
                            {Array(review.rating).fill().map((_, i) => (
                            <span key={i}>⭐</span>
                            ))}
                        </div>
                        <div className="reviews-card-date">{review.date}</div>
                      </div>
                  </div>
                  <p className="review-text">{review.reviewFull}</p>
                </div>
            </div>
            ))}
        </Slider>
    </div>
  );
};

export default Reviews;

import { useAuth } from "./AuthContext";
import { Navigate, Route, Routes } from "react-router-dom";

import AdminDashboard from "./components/AdminDashboard";

const ProtectedRoutes = () => {
    const { user, isLoading } = useAuth();

    if (isLoading) {
        return <div>Chargement...</div>;
    }

    return (
        <Routes>
            <Route path="/admin-dashboard" element={user ? <AdminDashboard /> : <Navigate to="/admin" />} />
        </Routes>
    );
};

export default ProtectedRoutes;
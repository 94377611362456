import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import "./styles/Footer.css";

const Footer = () => {
    const { t } = useTranslation();
    const [contact, setContact] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 699);

    useEffect(() => {
        fetch('/data/fr/contact.json')
          .then(response => response.json())
          .then(data => setContact(data.contact))
          .catch(error => console.error('Erreur lors du chargement des contacts:', error));
    }, []);   

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 599);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <footer className="footer">
            <div className="footer-branding">
                <h1>{t("footer.title")}</h1>
            </div>
            <div className="footer-content">
                <div className="footer-section">
                    <h4>{t("footer.contact")}</h4>
                    <p>{t("footer.email")} {contact.email}</p>
                    <p>{t("footer.phone")} {isMobile && (<br />)}{contact.phone} / {contact.phone2}</p>
                </div>
                <div className="footer-section">
                    <h4>{t("footer.legal")}</h4>
                    <Link to="/terms-of-sale">{t("footer.terms")}</Link>
                </div>
                <div className="footer-section">
                    <h4>{t("footer.pages")}</h4>
                    <Link to="/">{t("footer.home")}</Link>
                    <Link to="/gites">{t("footer.gites")}</Link>
                    <Link to="/about">{t("footer.about")}</Link>
                    <Link to="/contact">{t("footer.contactUs")}</Link>
                    <Link to="/reservation">{t("footer.reservation")}</Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
